import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Card } from '@app/components/common/Card/Card';
import { InputButton } from '@app/components/common/inputs/InputButton/InputButton';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { setBetCommission } from '@app/store/slices/eventPointSlice';
import { updateRewardAmount } from '@app/api/\bpageApi/eventPointManage.api';
import { useQuery } from 'react-query';
import {
    addChannelId,
    addGame,
    deleteChannelId,
    getBetCommission,
    getChannelIds,
    getGames
} from '@app/api/\bpageApi/betManage.api';
import { useBetStateList, useGamesStateList } from '@app/api/\bpageApi/stateList.api';
import { setBetGames, setDrawAllow, setIsOpen, setNewGameIndex } from '@app/store/slices/betSlice';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Radio } from '@app/components/common/Radio/Radio';
import { BetTable } from '@app/components/tables/BetTable/BetTable';
import { Title } from '@app/components/common/Title/Title';
import { PrefixInput } from '@app/components/common/inputs/PrefixInput/PrefixInput';
import { useNavigate } from 'react-router-dom';
import { isValidToken } from '@app/api/\bpageApi/common.api';
import { SubTitle } from '@app/components/common/Title/SubTitle';
import { DeletableCard } from '@app/components/common/DeletableCard/DeletableCard';

const SportBetManagePage: React.FC = () => {
    const navigate = useNavigate();
    useEffect(() => {
        isValidToken(navigate);
    });

    const { betStateList: stateList } = useBetStateList();
    const dispatch = useAppDispatch();
    const betCommissionQuery = useQuery('bet-commission', getBetCommission);
    const gamesQuery = useQuery('games', getGames);
    const isOpen = useAppSelector(state => state.bet.isOpen);
    const { gamesStateList } = useGamesStateList();
    const betGames = useAppSelector(state => state.bet.betGames);
    const newGameIndex = useAppSelector(state => state.bet.newGameIndex);
    const gameName = useAppSelector(state => state.bet.gameName);
    const homeTeamName = useAppSelector(state => state.bet.homeTeamName);
    const awayTeamName = useAppSelector(state => state.bet.awayTeamName);
    const date = useAppSelector(state => state.bet.date);
    const time = useAppSelector(state => state.bet.time);
    const drawAllow = useAppSelector(state => state.bet.drawAllow);
    const [channelId, setChannelId] = useState<number>(0);
    const [channelIds, setChannelIds] = useState<string[]>([]);
    const channelsQuery = useQuery('channels', getChannelIds);

    useEffect(() => {
        if (gamesQuery.isSuccess) {
            const gamesData = [...gamesQuery.data];
            const sortedData = gamesData
                .sort((a: any, b: any) => a.gameIndex - b.gameIndex)
                .reverse();
            dispatch(setBetGames(sortedData));

            gamesData.length > 0
                ? dispatch(setNewGameIndex(+sortedData[0]?.gameIndex + 1))
                : dispatch(setNewGameIndex(1));
        }
        if (betCommissionQuery.isSuccess) {
            const betCommissionData = betCommissionQuery.data;
            dispatch(setBetCommission(betCommissionData.betCommission));
        }

        if (channelsQuery.isSuccess) {
            const channelsData = channelsQuery.data;
            setChannelIds(channelsData);
        }
    }, [gamesQuery.isSuccess, betCommissionQuery.isSuccess, channelsQuery.isSuccess]);

    const desktopLayout = (
        <Col>
            {/* 베팅 수수료 변경 */}
            <div style={{ marginBottom: 15 }}>
                <Title>베팅 수수료 변경 </Title>
                <SubTitle>(단위 : %, 소수점으로 쓰지 말 것)</SubTitle>
                <Card style={{ width: '60%', minWidth: 900 }}>
                    {stateList.map((item, index) => {
                        return (
                            <InputButton
                                key={index}
                                prefix={item.name}
                                value={item.state}
                                onClickFunction={() =>
                                    updateRewardAmount(item.key, item.reason, item.state)
                                }
                                onChange={item.func}
                                type={item.type}
                                title="수정하기"
                            />
                        );
                    })}
                </Card>
            </div>

            {/* 채널 ID 관리 */}
            <div style={{ marginBottom: 15 }}>
                <Title> 베팅 허용 채널 ID 관리 </Title>
                <Card style={{ width: '60%', minWidth: 900 }}>
                    <InputButton
                        prefix="채널 ID"
                        value={channelId}
                        onClickFunction={() => addChannelId(channelId)}
                        onChange={setChannelId}
                        type="number"
                        title="추가하기"
                    />
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            marginTop: 5
                        }}
                    >
                        {channelIds?.map((item, index) => {
                            return (
                                <DeletableCard
                                    key={index}
                                    text={item}
                                    onClick={() => {
                                        deleteChannelId(item);
                                    }}
                                />
                            );
                        })}
                    </div>
                </Card>
            </div>

            {/* 새 경기정보 입력 */}
            <div style={{ marginBottom: 15 }}>
                <Row style={{ alignItems: 'center', marginBottom: 10 }}>
                    <Title>새 경기 정보 입력</Title>
                    <Button
                        style={{ marginLeft: 10, height: 35 }}
                        onClick={() => dispatch(setIsOpen(true))}
                    >
                        추가하기
                    </Button>
                </Row>
                <Card
                    style={{
                        width: '60%',
                        minWidth: 900,
                        display: isOpen ? 'block' : 'none'
                    }}
                >
                    {gamesStateList.map((item, index) => {
                        return (
                            <PrefixInput
                                key={index}
                                prefix={item.name}
                                onChange={item.func}
                                type={item.type}
                                placeholder={item.placeholder}
                            />
                        );
                    })}

                    <Radio
                        value={true}
                        checked={drawAllow}
                        onChange={e => dispatch(setDrawAllow(e.target.value))}
                    >
                        무승부 허용
                    </Radio>
                    <Radio
                        value={false}
                        checked={!drawAllow}
                        onChange={e => dispatch(setDrawAllow(e.target.value))}
                    >
                        무승부 비허용
                    </Radio>
                    <Row>
                        <Button
                            type="primary"
                            style={{ marginTop: 10, marginRight: 10, width: 140 }}
                            onClick={() => {
                                addGame(
                                    newGameIndex,
                                    gameName,
                                    homeTeamName,
                                    awayTeamName,
                                    date as number,
                                    time as number,
                                    drawAllow
                                );
                            }}
                        >
                            적용
                        </Button>
                        <Button
                            style={{ marginTop: 10, width: 140 }}
                            onClick={() => dispatch(setIsOpen(false))}
                        >
                            취소
                        </Button>
                    </Row>
                </Card>
            </div>
            <div style={{ marginBottom: 15 }}>
                <Title>현재 경기 리스트</Title>
                <Card style={{ minWidth: 1400 }}>
                    <BetTable games={betGames} />
                </Card>
            </div>
        </Col>
    );

    return (
        <>
            <PageTitle>Sport Bet Manage Page</PageTitle>
            {desktopLayout}
        </>
    );
};

export default SportBetManagePage;
