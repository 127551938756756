import React, { useEffect, useRef, useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { AwdTable } from '@app/components/tables/BasicTable/AwdTable';
import { PrefixInput } from '@app/components/common/inputs/PrefixInput/PrefixInput';
import { Card } from '@app/components/common/Card/Card';
import {
    getAwdAmount,
    getAwdLog,
    pressGiveAwdButton,
    adminReasonList
} from '@app/api/pageApi/awdManage.api';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { addLog } from '@app/store/slices/logsSlice';
import { useQuery } from 'react-query';
import { DefaultButton } from '@app/components/common/buttons/Button/SearchButton';
import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Title } from '@app/components/common/Title/Title';
import { isValidToken } from '@app/api/\bpageApi/common.api';
import { useNavigate } from 'react-router-dom';
import { UserAwdTable } from '@app/components/tables/BasicTable/UserAwdTable';
import { DayjsDatePicker } from '@app/components/common/pickers/DayjsDatePicker';
import { Color } from '@app/styles/themes/light/lightTheme';
import { SubTitle } from '@app/components/common/Title/SubTitle';

const AwdManagePage: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        isValidToken(navigate);
    }, []);

    const dispatch = useAppDispatch();
    const logsQuery = useQuery('logs', getAwdLog);
    if (logsQuery.isSuccess) {
        dispatch(addLog(logsQuery.data));
    }
    const logs = useAppSelector(state => state.logs);
    const [userLogs, setUserLogs] = useState([
        {
            date: '',
            awd: 0,
            reason: 0
        }
    ]);
    const [userId, setUserId] = useState('');
    const [userName, setUserName] = useState('');
    const [haveAwd, setHaveAwd] = useState(0);
    const [giveAwd, setGiveAwd] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const [reason, setReason] = useState(`지급 회수 사유`);
    const [reasonKey, setReasonKey] = useState(0);
    const [timeRange, setTimeRange] = useState([0, 0]);

    const errorMessageRef = useRef('Please enter a user ID or user name');

    if (userId || userName) {
        errorMessageRef.current = '';
    } else {
        errorMessageRef.current = 'Please enter a user ID or user name';
    }

    const pressSearchButton = async (userId: string, userName: string) => {
        const [startTime, endTime] = timeRange;
        const result = await getAwdAmount(userId, userName, startTime, endTime);

        if (!result || result.statusCode) return;
        setUserId(result.userId);
        setHaveAwd(result.amount);
        setUserLogs(result.logs);
        setIsVisible(true);
    };

    const items = [];
    for (const reason of adminReasonList) {
        const array = {
            key: `${reason.index}`,
            label: `${reason.reason}`,
            onClick: () => {
                setReason(`${reason.reason}`);
                setReasonKey(+`${reason.index}`);
            }
        };
        items.push(array);
    }

    const desktopLayout = (
        <div>
            {/* 유저 조회하기 */}
            <div style={isVisible ? { display: `none` } : {}}>
                <Title>유저 조회하기</Title>
                <Card style={{ width: `60%`, minWidth: 130 }}>
                    <PrefixInput
                        prefix="User Id"
                        onChange={setUserId}
                        readOnly={userName === '' ? false : true}
                        value={userId}
                    />
                    <PrefixInput
                        prefix="User Name"
                        onChange={setUserName}
                        readOnly={userId === '' ? false : true}
                    />
                    <DefaultButton
                        style={{ width: '100%', marginTop: 5 }}
                        onClick={() => pressSearchButton(userId, userName)}
                        disabled={userId === '' && userName === '' ? true : false}
                        type="primary"
                    >
                        조회하기
                    </DefaultButton>
                    <div>{errorMessageRef.current}</div>
                </Card>
            </div>

            {/* 부여, 차감 */}
            <div style={isVisible ? {} : { display: 'none' }}>
                <Title>유저에게 AWD 부여 / 차감하기</Title>
                <Card style={{ width: `60%`, minWidth: 900 }}>
                    <PrefixInput prefix="User Id" readOnly={true} value={userId} />
                    <PrefixInput prefix="보유 AWD" readOnly={true} value={haveAwd} />
                    <PrefixInput
                        prefix="부여 / 차감 AWD"
                        onChange={setGiveAwd}
                        placeholder="ex) 지급 : 15 입력 / 회수 : -20 입력"
                        value={giveAwd}
                        type="number"
                    />
                    <Dropdown
                        menu={{
                            items: items
                        }}
                        placement="bottom"
                        arrow
                    >
                        <Button
                            style={{
                                width: `100%`,
                                marginTop: 5,
                                marginBottom: 10
                            }}
                        >
                            {reason}
                        </Button>
                    </Dropdown>
                    <DefaultButton
                        style={{ width: '100%', marginTop: 5 }}
                        disabled={giveAwd !== 0 && reasonKey > 0 ? false : true}
                        type="primary"
                        onClick={() => pressGiveAwdButton(userId, giveAwd, reasonKey)}
                    >
                        부여 / 차감하기
                    </DefaultButton>
                </Card>
            </div>

            {/* 유저 별 히스토리 체크하기 */}
            <div style={isVisible ? {} : { display: 'none' }}>
                <div style={{ height: 25 }} />
                <Title>{userId}의 AWD 증감 내역</Title>
                <SubTitle>
                    ( 최근 내역 100개 까지만 조회됩니다. 조회기간을 최대 1개월까지 변경해보세요. )
                </SubTitle>
                <Card style={{ width: '60%', minWidth: 900 }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginBottom: 10
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                fontWeight: 'bold',
                                fontSize: 16,
                                width: 130,
                                marginRight: 8,
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: 48,
                                borderRadius: 7,
                                border: `1px solid ${Color.gray}`
                            }}
                        >
                            조회 기간
                        </div>
                        <DayjsDatePicker.RangePicker
                            style={{ width: '100%', marginRight: 8 }}
                            format="YYYY-MM-DD HH:mm"
                            onChange={value => {
                                if (!value?.[0] || !value?.[1]) return;
                                setTimeRange([value[0].unix(), value[1].unix()]);
                            }}
                        />
                        <Button
                            onClick={() => {
                                pressSearchButton(userId, userName);
                            }}
                        >
                            조회하기
                        </Button>
                    </div>

                    <UserAwdTable {...userLogs} />
                </Card>
            </div>

            {/* 히스토리 체크하기 */}
            <div style={!isVisible ? {} : { display: 'none' }}>
                <div style={{ height: 25 }} />
                <Title>어드민을 통한 AWD 부여, 차감 내역</Title>
                <SubTitle>
                    ( 최근 내역 100개 까지만 조회됩니다. 더 많은 내용은 위에서 유저를 조회하여
                    알아보세요. )
                </SubTitle>
                <Card style={{ minWidth: 1300 }}>
                    <AwdTable {...logs} />
                </Card>
            </div>
        </div>
    );

    return (
        <>
            <PageTitle>AWD Manage Page</PageTitle>
            {desktopLayout}
        </>
    );
};

export default AwdManagePage;
