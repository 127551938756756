import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import * as S from './LoginForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { login } from '@app/api/\bpageApi/sign.api';

interface LoginFormData {
    email: string;
    password: string;
    rememberMe?: boolean;
}

export const LoginForm: React.FC = () => {
    const initValues: LoginFormData = {
        email: localStorage.getItem('email') || '',
        password: localStorage.getItem('password') || ''
    };

    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (values: LoginFormData) => {
        if (values.rememberMe) {
            localStorage.setItem('email', values.email);
            localStorage.setItem('password', values.password);
        } else {
            localStorage.removeItem('email');
            localStorage.removeItem('password');
        }

        setLoading(true);
        const result = await login(values.email, values.password);
        localStorage.setItem('account', values.email);
        setLoading(false);

        if (result) navigate('/');
    };

    const pressSignUp = async () => {
        navigate('/auth/sign-up');
    };

    return (
        <Auth.FormWrapper>
            <BaseForm
                layout="vertical"
                onFinish={values => handleSubmit(values)}
                requiredMark="optional"
                initialValues={initValues}
            >
                <Auth.FormTitle>{'Log in'}</Auth.FormTitle>
                <S.LoginDescription>{'이메일과 비밀번호를 입력해주세요!'}</S.LoginDescription>
                <Auth.FormItem
                    name="email"
                    label="Email"
                    rules={[
                        { required: true, message: '이메일을 입력해주세요!' },
                        {
                            type: 'email',
                            message: '이메일 형식이 아닙니다!'
                        }
                    ]}
                >
                    <Auth.FormInput placeholder="이메일" />
                </Auth.FormItem>
                <Auth.FormItem
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: '비밀번호를 입력해주세요!' }]}
                >
                    <Auth.FormInputPassword placeholder="비밀번호" />
                </Auth.FormItem>
                <Auth.ActionsWrapper>
                    <BaseForm.Item name="rememberMe" valuePropName="checked" noStyle>
                        <Auth.FormCheckbox>
                            <S.RememberMeText>{'Remember account-Info'}</S.RememberMeText>
                        </Auth.FormCheckbox>
                    </BaseForm.Item>
                </Auth.ActionsWrapper>
                <BaseForm.Item noStyle>
                    <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
                        {'Log in'}
                    </Auth.SubmitButton>
                </BaseForm.Item>
                <BaseForm.Item noStyle>
                    <Auth.SingUpButton type="default" onClick={() => pressSignUp()}>
                        {'Sign up'}
                    </Auth.SingUpButton>
                </BaseForm.Item>
            </BaseForm>
        </Auth.FormWrapper>
    );
};
