import React, { useState } from 'react';
import { Header } from '../../../header/Header';
import MainSider from '../sider/MainSider/MainSider';
import MainContent from '../MainContent/MainContent';
import { MainHeader } from '../MainHeader/MainHeader';
import * as S from './MainLayout.styles';
import { Outlet } from 'react-router-dom';

const MainLayout: React.FC = () => {
    const [siderCollapsed, setSiderCollapsed] = useState(true);
    const toggleSider = () => setSiderCollapsed(!siderCollapsed);

    return (
        <S.LayoutMaster>
            <MainSider isCollapsed={true} setCollapsed={setSiderCollapsed} />
            <S.LayoutMain>
                <MainHeader isTwoColumnsLayout={false}>
                    <Header
                        toggleSider={toggleSider}
                        isSiderOpened={true}
                        isTwoColumnsLayout={false}
                    />
                </MainHeader>
                <MainContent id="main-content" $isTwoColumnsLayout={false}>
                    <div>
                        <Outlet />
                    </div>
                </MainContent>
            </S.LayoutMain>
        </S.LayoutMaster>
    );
};

export default MainLayout;
