import * as S from './Title.styles';

export interface SubTitleProps {
    className?: string;
    children: React.ReactNode;
}

export const SubTitle = ({ children, className, ...props }: SubTitleProps) => {
    return (
        <S.SubTitle className={className} {...props}>
            {' '}
            {children}
        </S.SubTitle>
    );
};
