import styled from 'styled-components';

interface InputButtonProps {
    isOpen: boolean;
}

export const Prefix = styled.div<InputButtonProps>`
    transition: all 0.5s ease;
    visibility: visible;
    opacity: 1;
    width: 200px;
    font-weight: bold;
    padding: 1px;
    color: black;

    ${props => !props.isOpen && 'opacity: 0; visibility: hidden'};
`;
