import * as S from './Title.styles';

export interface TitleProps {
    className?: string;
    children: React.ReactNode;
}

export const Title = ({ children, className, ...props }: TitleProps) => {
    return (
        <S.Wrapper>
            <S.Icon />
            <S.Title className={className} {...props}>
                {' '}
                {children}
            </S.Title>
        </S.Wrapper>
    );
};
