import React, { ChangeEvent, useEffect, useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Card } from '@app/components/common/Card/Card';
import { InputButton } from '@app/components/common/inputs/InputButton/InputButton';
import { useQuery } from 'react-query';
import { getMysteryBoxPoint, pressRewardButton } from '@app/api/\bpageApi/mysteryBoxManage.api';
import { updateRewardAmount } from '@app/api/\bpageApi/eventPointManage.api';
import { useMysteryBoxStateList } from '@app/api/\bpageApi/stateList.api';
import { Title } from '@app/components/common/Title/Title';
import { useNavigate } from 'react-router-dom';
import { isValidToken } from '@app/api/\bpageApi/common.api';

const MysteryBoxManagePage: React.FC = () => {
    const navigate = useNavigate();
    useEffect(() => {
        isValidToken(navigate);
    });

    const [winnersList, setWinnersList] = useState<
        [{ id: string; userId: string; rewardName: string; rewardCount: string }] | []
    >([]);
    const { mysteryBoxStateList: stateList } = useMysteryBoxStateList();
    const mysteryBoxPointQuery = useQuery('mystery-box', getMysteryBoxPoint);
    const mysteryBoxData = mysteryBoxPointQuery.data;

    useEffect(() => {
        if (mysteryBoxPointQuery.isSuccess) {
            if (mysteryBoxData) {
                for (const row of stateList) {
                    switch (row.dataName) {
                        case 'buyMysteryBox':
                            row.func(mysteryBoxData.cost.buyMysteryBox);
                            break;
                        case 'openMysteryBox':
                            row.func(mysteryBoxData.cost.openMysteryBox);
                            break;
                    }
                }
                setWinnersList(
                    mysteryBoxData.reward as unknown as [
                        { id: string; userId: string; rewardName: string; rewardCount: string }
                    ]
                );
            }
        }
    }, [mysteryBoxPointQuery.isSuccess]);

    const desktopLayout = (
        <div>
            <div>
                <Title>미스테리 박스 관리</Title>
                <Card style={{ width: '60%', minWidth: 900 }}>
                    {stateList.map((item, index) => {
                        return (
                            <InputButton
                                key={index}
                                prefix={item.name}
                                value={item.state}
                                onChange={item.func}
                                type={item.type}
                                onClickFunction={() =>
                                    updateRewardAmount(item.key, item.reason, item.state)
                                }
                                title="수정하기"
                            />
                        );
                    })}
                </Card>
            </div>

            <div style={{ height: 25 }} />
            <div>
                <Title>미스테리박스 Klay & USDC 당첨자 관리</Title>
                <Card style={{ width: `60%`, minWidth: 900 }}>
                    {winnersList.map((item, index) => {
                        return (
                            <InputButton
                                key={index}
                                prefix={item.userId}
                                value={item.rewardName + ' : ' + item.rewardCount}
                                onClickFunction={() =>
                                    pressRewardButton(
                                        item.rewardName,
                                        item.rewardCount,
                                        item.userId
                                    )
                                }
                                title="지급하기"
                            />
                        );
                    })}
                </Card>
            </div>
        </div>
    );

    return (
        <>
            <PageTitle>Mystery-box Manage</PageTitle>
            {desktopLayout}
        </>
    );
};

export default MysteryBoxManagePage;
function dispatch(arg0: any) {
    throw new Error('Function not implemented.');
}
