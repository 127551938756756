import { CloseCircleOutlined } from '@ant-design/icons';
import { Button } from '../buttons/Button/Button';
import { Input } from '../inputs/Input/Input';
import { Color } from '@app/styles/themes/light/lightTheme';

export const FilterSearchBox = ({
    selectedKeys,
    setSelectedKeys,
    confirm,
    close,
    clearFilters,
    placeholder
}: any) => {
    return (
        <div>
            <div
                style={{
                    paddingTop: 5,
                    paddingBottom: 10,
                    paddingLeft: 10,
                    paddingRight: 10
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        width: `100%`,
                        justifyContent: 'flex-end',
                        justifyItems: 'flex-end'
                    }}
                >
                    <CloseCircleOutlined
                        style={{ marginBottom: 5, color: Color.pink }}
                        onClick={() => {
                            close();
                            clearFilters();
                        }}
                    />
                </div>
                <Input
                    placeholder={placeholder}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => confirm()}
                    style={{ width: 250, marginBottom: 8, display: 'block' }}
                />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        type="ghost"
                        onClick={() => {
                            clearFilters();
                        }}
                        style={{ width: '50%', marginRight: 8 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            confirm();
                        }}
                        style={{ width: '50%' }}
                    >
                        Search
                    </Button>
                </div>
            </div>
        </div>
    );
};
