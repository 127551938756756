import themeReducer from '@app/store/slices/themeSlice';
import logsReducer from '@app/store/slices/logsSlice';
import eventPointReducer from '@app/store/slices/eventPointSlice';
import betReducer from '@app/store/slices/betSlice';

export default {
    theme: themeReducer,
    logs: logsReducer,
    eventPoint: eventPointReducer,
    bet: betReducer
};
