import * as S from './ShowingAccountHeader.styles';

export const ShowingAccountHeader: React.FC = props => {
    return (
        <S.Wrapper>
            <S.Prefix> 🧑🏻‍💻 현재 계정 : </S.Prefix>
            <S.Title>{props.children}</S.Title>
        </S.Wrapper>
    );
};
