import React from 'react';
import * as S from './MainSider/MainSider.styles';
import { RightOutlined } from '@ant-design/icons';
import { useResponsive } from 'hooks/useResponsive';
import logo from 'assets/logo.png';
import logoDark from 'assets/logo-dark.png';
import { useAppSelector } from '@app/hooks/reduxHooks';
import awLogo from 'assets/aw-logo.png';
import siteLogo from 'assets/site_logo.svg';

interface SiderLogoProps {
    isSiderCollapsed: boolean;
    toggleSider: () => void;
}
export const SiderLogo: React.FC<SiderLogoProps> = ({ isSiderCollapsed, toggleSider }) => {
    const { tabletOnly } = useResponsive();

    const theme = useAppSelector(state => state.theme.theme);

    const img = awLogo;

    return (
        <S.SiderLogoDiv>
            <S.SiderLogoLink to="/">
                <img src={img} alt="AnotherWorld" width={60} height={60} />
                <img src={siteLogo} alt="AnotherWorld" width={140} height={70} />
            </S.SiderLogoLink>
            {tabletOnly && (
                <S.CollapseButton
                    shape="circle"
                    size="small"
                    $isCollapsed={isSiderCollapsed}
                    icon={<RightOutlined rotate={isSiderCollapsed ? 0 : 180} />}
                    onClick={toggleSider}
                />
            )}
        </S.SiderLogoDiv>
    );
};
