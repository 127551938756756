import { css } from 'styled-components';

export const resetCss = css`
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    ::-webkit-scrollbar {
        width: 1rem;
    }
    ::-webkit-scrollbar-track {
        background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background-color: var(--scroll-color);
        border-radius: 1.25rem;
        border: 0.375rem solid transparent;
        background-clip: content-box;
    }

    ::-webkit-calendar-picker-indicator {
        height: 1.5rem;
        width: 1.5rem;
        background-color: #7ed3d6;
        border-radius: 0.4rem;
        padding: 0.25rem;
        display: flex;
    }

    body {
        font-weight: 500;
    }

    img {
        display: block;
    }
`;
