import { createSlice } from '@reduxjs/toolkit';

type InitialState = {
    newGameIndex?: number;
    betGames?: any[];
    isOpen?: boolean;
    gameName?: string;
    homeTeamName?: string;
    awayTeamName?: string;
    drawAllow?: boolean;
    date?: number;
    time?: number;
};

const initialState: InitialState = {
    newGameIndex: 0,
    betGames: [],
    isOpen: false,
    gameName: '',
    homeTeamName: '',
    awayTeamName: '',
    drawAllow: false,
    date: 0,
    time: 0
};

export const betSlice = createSlice({
    name: 'bet',
    initialState,
    reducers: {
        setNewGameIndex: (state, action) => {
            state.newGameIndex = action.payload;
        },
        setBetGames: (state, action) => {
            state.betGames = action.payload;
        },
        setIsOpen: (state, action) => {
            state.isOpen = action.payload;
        },
        setGameName: (state, action) => {
            state.gameName = action.payload;
        },
        setHomeTeamName: (state, action) => {
            state.homeTeamName = action.payload;
        },
        setAwayTeamName: (state, action) => {
            state.awayTeamName = action.payload;
        },
        setDrawAllow: (state, action) => {
            state.drawAllow = action.payload;
        },
        setDate: (state, action) => {
            state.date = action.payload;
        },
        setTime: (state, action) => {
            state.time = action.payload;
        }
    }
});

export const {
    setNewGameIndex,
    setBetGames,
    setIsOpen,
    setGameName,
    setHomeTeamName,
    setAwayTeamName,
    setDate,
    setTime,
    setDrawAllow
} = betSlice.actions;

export default betSlice.reducer;
