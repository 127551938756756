import { shadeColor } from '@app/utils/utils';
import { graphic } from 'echarts';
import { BASE_COLORS } from '../constants';
import { ITheme } from '../types';

export const Color = {
    paleMint: `#7ed3d6`,
    black: `#000000`,
    white: `#ffffff`,
    paleGray: `#edf3f5`,
    lightGray: `#e4eef2`,
    mint: `#7ed3d6`,
    tintMint: `#00becf`,
    darkGray: `#6A7985`,
    darkMint: `#00becf`,
    pink: `#f56799`,
    gray: `#adb9c4`
};
const chartColors = {
    chartTooltipLabel: '#6A7985',
    chartColor1: Color.darkMint,
    chartColor1Tint: '#2983D8',
    chartColor2: '#35A0DC',
    chartColor2Tint: '#67C5FA',
    chartColor3: '#FFB155',
    chartColor3Tint: '#FFA800',
    chartColor4: '#31A652',
    chartColor4Tint: '#89DCA0',
    chartColor5: '#FF5252',
    chartColor5Tint: '#FFC1C1',
    chartPrimaryGradient: new graphic.LinearGradient(0, 0, 0, 1, [
        {
            offset: 0,
            color: 'rgba(0, 110, 211, 0.5)'
        },
        {
            offset: 1,
            color: 'rgba(255, 225, 255, 0)'
        }
    ]),
    chartSecondaryGradient: new graphic.LinearGradient(0, 0, 0, 1, [
        {
            offset: 0,
            color: 'rgba(255, 82, 82, 0.5)'
        },
        {
            offset: 1,
            color: 'rgba(255, 255, 255, 0)'
        }
    ]),
    chartSecondaryGradientSpecular: new graphic.LinearGradient(0, 0, 0, 1, [
        {
            offset: 0,
            color: 'rgba(255, 255, 255, 0)'
        },
        {
            offset: 1,
            color: 'rgba(255, 82, 82, 0.5)'
        }
    ])
};

export const lightColorsTheme: ITheme = {
    primary: Color.black,
    primary1: Color.white,
    primaryGradient: Color.paleMint,
    light: Color.mint,
    secondary: Color.black,
    error: '#FF5252',
    warning: '#FFB155',
    success: '#30AF5B',
    background: Color.white,
    secondaryBackground: '#dae3e0',
    secondaryBackgroundSelected: shadeColor('#F8FBFF', -5),
    additionalBackground: Color.white,
    collapseBackground: Color.tintMint,
    timelineBackground: '#F8FBFF',
    siderBackground: Color.lightGray,
    spinnerBase: '#f42f25',
    scroll: Color.lightGray,
    border: Color.lightGray,
    borderNft: '#79819A',
    textMain: '#404040',
    textLight: '#9A9B9F',
    textSuperLight: '#BEC0C6',
    textSecondary: Color.black,
    textDark: Color.darkGray,
    textNftLight: '#79819A',
    textSiderPrimary: Color.mint,
    textSiderSecondary: '#ffffff',
    subText: 'rgba(0, 0, 0, 0.45)',
    shadow: 'rgba(0, 0, 0, 0.07)',
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.09)',
    boxShadowHover: '0 4px 16px 0 rgba(0, 0, 0, 0.4)',
    boxShadowNft:
        '0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    boxShadowNftSecondary:
        '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    dashboardMapBackground: '#EAF5FF',
    dashboardMapCircleColor: '#9BC2E7',
    dashboardMapControlDisabledBackground: '#d3dee0',
    notificationSuccess: '#EFFFF4',
    notificationPrimary: '#D7EBFF',
    notificationWarning: '#FFF4E7',
    notificationError: '#FFE2E2',
    heading: '#13264d',
    borderBase: `#c9cdd6`,
    disable: 'rgba(0, 0, 0, 0.25)',
    disabledBg: '#d3dee0',
    layoutBodyBg: Color.lightGray,
    layoutHeaderBg: 'transparent',
    layoutSiderBg: 'rgba(0, 0, 0, 0.9)',
    inputPlaceholder: '#8c8c8c',
    itemHoverBg: Color.lightGray,
    backgroundColorBase: '#F5F5F5',
    avatarBg: Color.lightGray,
    alertTextColor: BASE_COLORS.white,
    breadcrumb: 'rgba(0, 0, 0, 0.45)',
    icon: '#a9a9a9',
    iconHover: 'rgba(0, 0, 0, 0.75)',
    ...chartColors
};
