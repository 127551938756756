import { notificationController } from '@app/controllers/notificationController';
import { errorAlert, expiredAlert } from './Alert.api';

export const headers = () => {
    const token = localStorage.getItem('token');
    return { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` };
};

export const isValidToken = async (navigate: any) => {
    const token = localStorage.getItem('token');
    if (!token) {
        await expiredAlert('로그인이 만료되었습니다. 다시 로그인해주세요. ', navigate);
    }
};

/* Validity Check */
export const validityCheck = (amount: number, reason: number) => {
    if (amount === 0) {
        notificationController.error({
            message: 'AWD를 입력해주세요.'
        });
        return false;
    } else if (reason === 0) {
        notificationController.error({
            message: '사유를 선택해주세요.'
        });
        return false;
    }
    return true;
};

/* Fail */
export const badRequest = (res: { statusCode: number } | undefined): boolean => {
    if (res === undefined) return false;
    if (res.statusCode !== 400) return false;
    notificationController.error({
        message: '입력값이 잘못 되었습니다.'
    });
    return true;
};

/* Expired */
export const CheckIfExpired = async (status: number): Promise<void> => {
    if (status !== 401) return;
    localStorage.removeItem('token');
    await errorAlert('로그인이 만료되었습니다. 다시 로그인해주세요! ');
};
