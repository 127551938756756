import { createSlice } from '@reduxjs/toolkit';

export type InitialState = {
    chatPoint?: number;
    miningPoint?: number;
    inviteRewardPoint?: number;
    invite14daysPoint?: number;
    inviteMaxCount?: number;
    gmInviteCode?: string;
    mysteryBoxOpenPoint?: number;
    mysteryBoxBuyPoint?: number;
    messageRewardDelayTime?: number;
    mysteryBoxBuyDelayTime?: number;
    twitterRetweetLikeReward?: number;
    betCommission?: number;
};

const initialState: InitialState = {
    chatPoint: 0,
    miningPoint: 0,
    inviteRewardPoint: 0,
    invite14daysPoint: 0,
    inviteMaxCount: 0,
    gmInviteCode: '',
    mysteryBoxOpenPoint: 0,
    mysteryBoxBuyPoint: 0,
    messageRewardDelayTime: 0,
    mysteryBoxBuyDelayTime: 0,
    twitterRetweetLikeReward: 0,
    betCommission: 0
};

export const eventPointSlice = createSlice({
    name: 'event-point',
    initialState,
    reducers: {
        setMiningPoint: (state, action) => {
            state.miningPoint = action.payload;
        },
        setChatPoint: (state, action) => {
            state.chatPoint = action.payload;
        },
        setInviteRewardPoint: (state, action) => {
            state.inviteRewardPoint = action.payload;
        },
        setInvite14daysPoint: (state, action) => {
            state.invite14daysPoint = action.payload;
        },
        setInviteMaxCount: (state, action) => {
            state.inviteMaxCount = action.payload;
        },
        setGmInviteCode: (state, action) => {
            state.gmInviteCode = action.payload;
        },
        setMysteryBoxOpenPoint: (state, action) => {
            state.mysteryBoxOpenPoint = action.payload;
        },
        setMysteryBoxBuyPoint: (state, action) => {
            state.mysteryBoxBuyPoint = action.payload;
        },
        setMessageRewardDelayTime: (state, action) => {
            state.messageRewardDelayTime = action.payload;
        },
        setMysteryBoxBuyDelayTime: (state, action) => {
            state.mysteryBoxBuyDelayTime = action.payload;
        },
        setTwitterRetweetLikeReward: (state, action) => {
            state.twitterRetweetLikeReward = action.payload;
        },
        setBetCommission: (state, action) => {
            state.betCommission = action.payload;
        }
    }
});

export const {
    setChatPoint,
    setMiningPoint,
    setInviteRewardPoint,
    setInvite14daysPoint,
    setInviteMaxCount,
    setGmInviteCode,
    setMysteryBoxOpenPoint,
    setMysteryBoxBuyPoint,
    setMessageRewardDelayTime,
    setMysteryBoxBuyDelayTime,
    setTwitterRetweetLikeReward,
    setBetCommission
} = eventPointSlice.actions;

export default eventPointSlice.reducer;
