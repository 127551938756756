import React, { useEffect } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { InputButton } from '@app/components/common/inputs/InputButton/InputButton';
import { Card } from '@app/components/common/Card/Card';
import { getEventPoint, updateRewardAmount } from '@app/api/\bpageApi/eventPointManage.api';
import { useQuery } from 'react-query';
import { useEventPointStateList } from '@app/api/\bpageApi/stateList.api';
import { Title } from '@app/components/common/Title/Title';
import { useNavigate } from 'react-router-dom';
import { isValidToken } from '@app/api/\bpageApi/common.api';

const EventPointManagePage: React.FC = () => {
    const { eventPointStateList: stateList } = useEventPointStateList();
    const eventPointQuery = useQuery('event-point', getEventPoint);
    const data = eventPointQuery.data;

    const navigate = useNavigate();
    useEffect(() => {
        isValidToken(navigate);
    });

    useEffect(() => {
        if (eventPointQuery.isSuccess) {
            for (const row of stateList) {
                const dataName = row.dataName;
                row.func(data[dataName]);
            }
        }
    }, [eventPointQuery.isSuccess]);

    const desktopLayout = (
        <div>
            <Title>이벤트 포인트 관리</Title>
            <div>
                <Card style={{ width: `60%`, minWidth: 900 }}>
                    {stateList.map((item, index) => {
                        return (
                            <InputButton
                                key={index}
                                prefix={item.name}
                                value={item.state}
                                onClickFunction={() =>
                                    updateRewardAmount(item.key, item.reason, item.state)
                                }
                                onChange={item.func}
                                type={item.type}
                                title="수정하기"
                            />
                        );
                    })}
                </Card>
            </div>
        </div>
    );

    return (
        <>
            <PageTitle>Event Point Manage Page</PageTitle>
            {desktopLayout}
        </>
    );
};

export default EventPointManagePage;
