import styled from 'styled-components';

interface PrefixProps {
    isOpen: boolean;
}

export const Prefix = styled.div<PrefixProps>`
    transition: all 0.5s ease;
    visibility: visible;
    opacity: 1;
    width: 150px;
    font-weight: bold;

    ${props => !props.isOpen && 'opacity: 0; visibility: hidden'};
`;
