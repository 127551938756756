import { Color } from '@app/styles/themes/light/lightTheme';
import styled from 'styled-components';

export const Box = styled.div`
    position: relative;
    width: 200px;
    height: 65px;
    border-radius: 8px;
    background-color: ${Color.white};
    border: 1px solid ${Color.gray};
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    background-color: ${Color.lightGray};
`;
