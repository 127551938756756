import React from 'react';
import { Input, InputProps } from '../Input/Input';
import * as S from './InputButton.styles';

import { Button } from '../../buttons/Button/Button';
import { Color } from '@app/styles/themes/light/lightTheme';
import { Row } from '../../forms/StepForm/StepForm.styles';

export interface InputButtonProps extends InputProps {
    prefix: React.ReactNode;
    isOpenPrefix?: boolean;
    onChange?: any;
    onClickFunction?: any;
    type?: string;
    title?: string;
    key?: number;
}

export const InputButton: React.FC<InputButtonProps> = ({
    prefix,
    isOpenPrefix = true,
    ...props
}) => (
    <div style={{ paddingTop: 5, paddingBottom: 5 }}>
        <Row>
            <Input
                key={props.key}
                prefix={<S.Prefix isOpen={isOpenPrefix}>{prefix}</S.Prefix>}
                {...props}
                onChange={e => {
                    if (props.onChange) {
                        props.onChange(e.target.value);
                    }
                }}
                type={props.type}
            />
            <Button
                onClick={() => props.onClickFunction()}
                style={{ backgroundColor: Color.lightGray }}
            >
                {props.title}
            </Button>
        </Row>
    </div>
);
