import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { notificationController } from '@app/controllers/notificationController';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './SignUpForm.styles';
import { signUp } from '@app/api/\bpageApi/sign.api';

interface SignUpFormData {
    email: string;
    password: string;
    code: string;
}

const initValues = {
    email: '',
    password: '',
    confirmPassword: '',
    termOfUse: true
};

export const SignUpForm: React.FC = () => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);

    const handleSubmit = async (values: SignUpFormData) => {
        setLoading(true);
        try {
            const result = await signUp(values.email, values.password, values.code);
            if (result) {
                notificationController.success({
                    message: '계정 생성 성공',
                    description: '성공적으로 계정을 생성하였습니다.'
                });
                navigate('/auth/login');
            } else {
                setLoading(false);
            }
        } catch (err: any) {
            notificationController.error({ message: err.message });
            setLoading(false);
        }
    };

    return (
        <Auth.FormWrapper>
            <BaseForm
                layout="vertical"
                onFinish={handleSubmit}
                requiredMark="optional"
                initialValues={initValues}
            >
                <S.Title>Sign Up</S.Title>
                <Auth.FormItem
                    name="email"
                    label="Email"
                    rules={[
                        { required: true, message: '이메일을 입력해주세요!' },
                        {
                            type: 'email',
                            message: '이메일 형식이 아닙니다!'
                        }
                    ]}
                >
                    <Auth.FormInput placeholder="Email" />
                </Auth.FormItem>
                <Auth.FormItem
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: '비밀번호를 입력해주세요!' }]}
                >
                    <Auth.FormInputPassword placeholder={'Password'} />
                </Auth.FormItem>
                <Auth.FormItem
                    label={'Confirm Password'}
                    name="confirmPassword"
                    dependencies={['password']}
                    rules={[
                        { required: true, message: '비밀번호를 다시 한번 입력해주세요!' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('비밀번호가 일치하지 않습니다!'));
                            }
                        })
                    ]}
                >
                    <Auth.FormInputPassword placeholder={'Confirm Password'} />
                </Auth.FormItem>
                <Auth.FormItem
                    label="Admin Code"
                    name="code"
                    rules={[
                        { required: true, message: '가입에 필요한 어드민코드를 입력해주세요!' }
                    ]}
                >
                    <Auth.FormInputPassword placeholder={'Admin Code'} />
                </Auth.FormItem>
                <Auth.ActionsWrapper>
                    <BaseForm.Item name="termOfUse" valuePropName="checked" noStyle>
                        <Auth.FormCheckbox>
                            <Auth.Text>보안에 유의하겠습니다.</Auth.Text>
                        </Auth.FormCheckbox>
                    </BaseForm.Item>
                </Auth.ActionsWrapper>
                <BaseForm.Item noStyle>
                    <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
                        {'계정 생성하기'}
                    </Auth.SubmitButton>
                </BaseForm.Item>

                <Auth.FooterWrapper>
                    <Auth.Text>
                        {'Already have an accont?'}{' '}
                        <Link to="/auth/login">
                            <Auth.LinkText>{'로그인하러 가기'}</Auth.LinkText>
                        </Link>
                    </Auth.Text>
                </Auth.FooterWrapper>
            </BaseForm>
        </Auth.FormWrapper>
    );
};
