import { CloseCircleOutlined } from '@ant-design/icons';
import * as S from './DeletableCard.styles';
import { Color } from '@app/styles/themes/light/lightTheme';

interface DeletableCardProps {
    text: string;
    onClick?: () => void;
}

export const DeletableCard: React.FC<DeletableCardProps> = ({ text, onClick }) => {
    return (
        <S.Box>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}
            >
                <CloseCircleOutlined
                    style={{
                        margin: '3px',
                        color: Color.pink,
                        backgroundColor: Color.white,
                        borderRadius: '50%'
                    }}
                    onClick={onClick}
                />
            </div>
            {text}
            <div style={{ height: 50, width: `100%` }} />
        </S.Box>
    );
};
