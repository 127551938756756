import { headers, CheckIfExpired } from './common.api';
import { confirmAlert, errorAlert, successAlert } from './Alert.api';
import { notificationController } from '@app/controllers/notificationController';
import { API_BASE_URL } from '@app/consts/consts';

export const getEventPoint = async () => {
    try {
        const res = await fetch(`${API_BASE_URL}/admin/rewards`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });
        await CheckIfExpired(res.status);
        const data = await res.json();
        return data;
    } catch (err: any) {
        notificationController.error({
            message: `eventPoint 조회에 실패했습니다. : ${err.message}`
        });
    }
};

export const updateRewardAmount = async (
    key: string,
    type: number,
    amount: number | string | undefined
) => {
    try {
        const message = `❗️ <strong> ${amount} </strong>로 변경합니다. <br>
    정말로 실행하시겠습니까?
  `;
        if (!(await confirmAlert(message))) return;
        const res = await fetch(`${API_BASE_URL}/admin/rewards`, {
            method: 'PATCH',
            headers: headers(),
            body: JSON.stringify({ message: key, type, amount })
        });

        await CheckIfExpired(res.status);
        const data = await res.json();
        if (data.message === 'success') {
            await successAlert('변경에 성공하였습니다.');
        } else {
            await errorAlert('변경에 실패하였습니다.');
        }
    } catch (err: any) {
        notificationController.error({
            message: `eventPoint 변경에 실패했습니다. : ${err.message}`
        });
    }
};
