import React from 'react';
import { Input, InputProps } from '../Input/Input';
import * as P from './PrefixInput.styles';

export interface PrefixInputProps extends InputProps {
    prefix: React.ReactNode;
    onChange?: any;
    isOpenPrefix?: boolean;
    type?: string;
}

export const PrefixInput: React.FC<PrefixInputProps> = ({
    prefix,
    isOpenPrefix = true,
    ...props
}) => (
    <div style={{ paddingTop: 5, paddingBottom: 5 }}>
        <Input
            prefix={<P.Prefix isOpen={isOpenPrefix}>{prefix}</P.Prefix>}
            {...props}
            onChange={e => {
                if (props.onChange) {
                    props.onChange(e.target.value);
                }
            }}
            type={props.type}
        />
    </div>
);
