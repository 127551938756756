import { Popconfirm, Form, TablePaginationConfig, Space } from 'antd';
import { Table } from 'components/common/Table/Table';
import { Pagination, getBetTableData, BetTableRow } from 'api/table.api';
import { Button } from 'components/common/buttons/Button/Button';
import { useMounted } from '@app/hooks/useMounted';
import { BetTableCell } from './BetTableCell';
import { useCallback, useEffect, useState } from 'react';
import { unixTimeToHtml } from '@app/utils/utils';
import { Input } from '@app/components/common/inputs/Input/Input';
import { Radio } from '@app/components/common/Radio/Radio';
import {
    caculateBetTime,
    editGame,
    parceResult,
    pressDeleteGameButton,
    pressResultInsertButton
} from '@app/api/\bpageApi/betManage.api';
import { ONE_HOUR } from '@app/consts/consts';
import { notificationController } from '@app/controllers/notificationController';

const initialPagination: Pagination = {
    current: 1,
    pageSize: 20
};

export const BetTable = (games: any) => {
    const [gameStart, setGameStart] = useState(Math.floor(new Date().getTime() / 1000));

    const [form] = Form.useForm();
    const [tableData, setTableData] = useState<{
        data: any;
        pagination: Pagination;
        loading: boolean;
    }>({
        data: [],
        pagination: initialPagination,
        loading: false
    });
    const [editingGameIndex, setEditingGameIndex] = useState(0);
    const { isMounted } = useMounted();
    const [isDrawAllow, setIsDrawAllow] = useState(false);
    const [isEditingDate, setIsEditingDate] = useState(false);

    const fetch = useCallback(
        (pagination: Pagination) => {
            setTableData(tableData => ({ ...tableData, loading: true }));
            getBetTableData(pagination).then(res => {
                if (isMounted.current) {
                    setTableData({ data: res.data, pagination: res.pagination, loading: false });
                }
            });
        },
        [isMounted]
    );

    useEffect(() => {
        fetch(initialPagination);
    }, [fetch]);

    const handleTableChange = (pagination: TablePaginationConfig) => {
        fetch(pagination);
        cancel();
    };

    const isEditing = (record: BetTableRow) => record.gameIndex === editingGameIndex;

    const edit = (record: Partial<BetTableRow> & { gameIndex: any } & any) => {
        setIsDrawAllow(record.drawAllow);
        form.setFields([
            {
                name: 'gameName',
                value: record.gameName
            },
            {
                name: 'homeTeamName',
                value: record.homeTeamName
            },
            {
                name: 'awayTeamName',
                value: record.awayTeamName
            }
        ]);

        setEditingGameIndex(record.gameIndex);
    };

    const cancel = () => {
        setEditingGameIndex(0);
        setIsEditingDate(false);
    };

    const save = async (gameIndex: any) => {
        try {
            const row = (await form.validateFields()) as BetTableRow;
            row.gameIndex = +gameIndex;
            row.drawAllow = isDrawAllow;
            const time = caculateBetTime(gameStart);
            row.betStartTime = time.betStartTime;
            row.betEndTime = time.betEndTime;
            editGame(row);
        } catch (err: any) {
            notificationController.error(err.message);
        }
    };

    const columns = [
        {
            title: '#',
            dataIndex: 'gameIndex',
            width: '3%',
            editable: false
        },
        {
            title: '경기명',
            dataIndex: 'gameName',
            width: '10%',
            editable: true
        },
        {
            title: 'Home',
            dataIndex: 'homeTeamName',
            width: '9%',
            editable: true
        },
        {
            title: 'Away',
            dataIndex: 'awayTeamName',
            width: '9%',
            editable: true
        },
        {
            title: '무승부',
            dataIndex: 'drawAllow',
            width: '7%',
            render: (drawAllow: boolean, record: BetTableRow) => {
                const editable = isEditing(record);
                return (
                    <Space>
                        {editable ? (
                            <>
                                <Radio
                                    value={true}
                                    checked={isDrawAllow}
                                    onChange={e => setIsDrawAllow(e.target.value)}
                                >
                                    ✅
                                </Radio>
                                <Radio
                                    value={false}
                                    checked={!isDrawAllow}
                                    onChange={e => setIsDrawAllow(e.target.value)}
                                >
                                    ❌
                                </Radio>
                            </>
                        ) : drawAllow ? (
                            '✔️'
                        ) : (
                            '❌'
                        )}
                    </Space>
                );
            }
        },
        {
            title: '베팅 시작 시간',
            dataIndex: 'betStartTime',
            width: '8%',
            editable: false,
            render: (betStartTime: any) => unixTimeToHtml(+betStartTime)
        },
        {
            title: '베팅 종료 시간',
            dataIndex: 'betEndTime',
            width: '8%',
            editable: false,
            render: (betEndTime: any) => unixTimeToHtml(+betEndTime)
        },
        {
            title: '게임 시작 시간',
            width: '8%',
            render: (record: BetTableRow) => {
                if (record.betEndTime) {
                    const gameStartTime = +record.betEndTime + 1 * ONE_HOUR;
                    if (isEditing(record) && !isEditingDate) {
                        setGameStart(gameStartTime);
                        setIsEditingDate(true);
                    }

                    const editable = isEditing(record);
                    return (
                        <Space>
                            {editable ? (
                                <>
                                    <Input
                                        type="datetime-local"
                                        value={new Date((gameStart + 9 * ONE_HOUR) * 1000)
                                            .toISOString()
                                            .slice(0, -8)}
                                        onChange={e => {
                                            setGameStart(
                                                Math.floor(
                                                    new Date(e.target.value).getTime() / 1000
                                                )
                                            );
                                        }}
                                    />
                                </>
                            ) : (
                                unixTimeToHtml(gameStartTime)
                            )}
                        </Space>
                    );
                }
            }
        },
        {
            title: '총 수수료 (AWD)',
            dataIndex: 'totalCommission',
            width: '7%',
            editable: false
        },

        {
            title: '결과',
            dataIndex: 'result',
            width: '6%',
            render: (result: number, record: BetTableRow) => {
                const content = parceResult(result, record);
                if (content === 'needToInsertResult') {
                    return (
                        <Space>
                            <Button onClick={() => pressResultInsertButton(record)}>
                                결과 입력
                            </Button>
                        </Space>
                    );
                }
                return <Space>{content}</Space>;
            }
        },

        {
            title: 'Actions',
            dataIndex: 'actions',
            width: '15%',
            render: (action: string, record: BetTableRow) => {
                const editable = isEditing(record);
                const now = new Date().getTime() / 1000;
                if (record.betStartTime && record.gameIndex) {
                    return (
                        <Space>
                            {editable ? (
                                <>
                                    <Button type="primary" onClick={() => save(record.gameIndex)}>
                                        Save
                                    </Button>
                                    <Popconfirm title="수정을 취소하시겠습니까?" onConfirm={cancel}>
                                        <Button type="ghost">Cancel</Button>
                                    </Popconfirm>
                                </>
                            ) : (
                                <>
                                    <Button
                                        type="ghost"
                                        disabled={
                                            +record.betStartTime < now
                                                ? true
                                                : editingGameIndex !== 0
                                        }
                                        onClick={() => {
                                            edit(record);
                                        }}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        type="default"
                                        danger
                                        onClick={() => {
                                            if (record.gameIndex && record.betStartTime) {
                                                pressDeleteGameButton(
                                                    +record.gameIndex,
                                                    +record.betStartTime
                                                );
                                            }
                                        }}
                                        disabled={
                                            +record.betStartTime < now
                                                ? true
                                                : editingGameIndex !== 0
                                        }
                                    >
                                        Delete
                                    </Button>
                                </>
                            )}
                        </Space>
                    );
                }
            }
        }
    ];

    const mergedColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record: BetTableRow) => ({
                editing: isEditing(record),
                dataIndex: col.dataIndex,
                title: col.title
            })
        };
    });

    return (
        <Form form={form} component={false}>
            <Table
                components={{
                    body: {
                        cell: BetTableCell
                    }
                }}
                dataSource={games.games}
                columns={mergedColumns}
                rowClassName="editable-row"
                onChange={handleTableChange}
                loading={tableData.loading}
                scroll={{ x: 800 }}
            />
        </Form>
    );
};
