import React from 'react';
import { CardProps as AntCardProps } from 'antd';
import { defaultPaddings } from '@app/constants/defaultPaddings';
import * as S from './Card.styles';

export interface CardProps extends AntCardProps {
    className?: string;
    padding?: string | number | [number, number];
    autoHeight?: boolean;
}

export const Card: React.FC<CardProps> = ({
    className,
    padding,
    size,
    autoHeight = true,
    children,
    ...props
}) => {
    return (
        <S.Card
            size="default"
            className={className}
            bordered={false}
            $padding={padding || padding === 0 ? padding : defaultPaddings.desktop}
            $autoHeight={autoHeight}
            {...props}
        >
            {children}
        </S.Card>
    );
};
