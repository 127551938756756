import { useState } from 'react';
import { DailyBotDataTableRow } from 'api/table.api';
import { Table } from 'components/common/Table/Table';
import { ColumnsType } from 'antd/es/table';
import { parseAwdReason } from '@app/api/\bpageApi/dataManage.api';
import { Color } from '@app/styles/themes/light/lightTheme';

export const DailyBotDataTable = (botData: any) => {
    const [tableData, setTableData] = useState<{
        data: DailyBotDataTableRow[];
        loading: boolean;
    }>({
        data: [],
        loading: false
    });

    const columns: ColumnsType<DailyBotDataTableRow> = [
        {
            title: `Reason`,
            dataIndex: `total`,
            render: (reason: string) => {
                return (
                    <div
                        style={{
                            color: Color.black,
                            fontWeight: `bold`
                        }}
                    >
                        {reason}
                    </div>
                );
            },
            width: `5%`
        },
        {
            title: ``,
            dataIndex: `mainReason`,
            render: (reason: string) => {
                return (
                    <div
                        style={{
                            color: Color.black,
                            fontWeight: `bold`
                        }}
                    >
                        {reason}
                    </div>
                );
            },
            width: `8%`
        },
        {
            title: ``,
            dataIndex: `detailReason`,
            render: (detailReason: number, record: DailyBotDataTableRow) => {
                const parcedReason = parseAwdReason(detailReason);

                return record.mainReason === parcedReason ? ' ' : parcedReason;
            },
            width: `6%`
        },
        {
            title: `Count`,
            dataIndex: `cnt`,
            render: cnt => {
                return cnt === 0 ? '-' : cnt;
            },
            width: `10%`
        },
        {
            title: `AWD`,
            dataIndex: `awd`,
            width: `10%`,
            render: awd => {
                return awd === 0 ? (
                    <div
                        style={{
                            color: Color.darkGray
                        }}
                    >
                        -
                    </div>
                ) : awd > 0 ? (
                    <div
                        style={{
                            color: Color.darkMint,
                            fontWeight: `bold`
                        }}
                    >
                        +{awd}
                    </div>
                ) : (
                    <div
                        style={{
                            color: Color.pink,
                            fontWeight: `bold`
                        }}
                    >
                        {awd}
                    </div>
                );
            }
        },
        {
            title: `User Earned`,
            dataIndex: `earn`,
            width: `10%`,
            render: earn => {
                return (
                    <div style={{ color: Color.darkMint }}> {earn === 0 ? '-' : `+${earn}`} </div>
                );
            }
        },
        {
            title: `Burned`,
            dataIndex: `burn`,
            width: `10%`,
            render: burn => {
                return <div style={{ color: Color.pink }}> {burn === 0 ? '-' : burn} </div>;
            }
        }
    ];

    return (
        <Table
            columns={columns}
            dataSource={botData.botData}
            loading={tableData.loading}
            scroll={{ x: 800 }}
            pagination={false}
        />
    );
};
