import styled from 'styled-components';
import { FONT_SIZE } from '@app/styles/themes/constants';
import { Color } from '@app/styles/themes/light/lightTheme';

export const Title = styled.div`
    color: black;
    font-size: ${FONT_SIZE.lg};
    font-weight: bold;
`;

export const SubTitle = styled.div`
    color: ${Color.darkGray};
    font-size: ${FONT_SIZE.xs};
    margin-bottom: 0.5rem;
    margin-left: 1rem;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0.2rem;
    margin-bottom: 0.3rem;
    align-items: center;
`;

export const Icon = styled.div`
    background-color: ${Color.tintMint};
    height: 1rem;
    width: 0.3rem;
    margin-right: 0.7rem;
    border-radius: 1rem;
`;
