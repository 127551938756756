import {
    dateToUnixTimeBySeconds,
    getAfterSixHours,
    parceDateTime,
    unixTimeToString
} from '@app/utils/utils';
import { BetTableRow } from '../table.api';
import { headers, CheckIfExpired } from './common.api';
import Swal from 'sweetalert2';
import { notificationController } from '@app/controllers/notificationController';
import { confirmAlert, errorAlert, successAlert } from './Alert.api';
import { Color } from '@app/styles/themes/light/lightTheme';
import { API_BASE_URL, FIVE_MINUTES, ONE_HOUR } from '@app/consts/consts';

/********** Game CRUD ************/
/* Get games list */
export const getGames = async (): Promise<{ gameIndex: number }[]> => {
    const res = await fetch(`${API_BASE_URL}/admin/bet/games`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    });
    CheckIfExpired(res.status);
    const data = (await res.json()).reverse();
    return data;
};

/* Edit Game */
export const editGame = async (row: BetTableRow): Promise<void> => {
    try {
        if (row.betEndTime) {
            const message = `❗️ 다음과 같이 경기를 수정합니다 <br>
    <strong> ✓ Game Number : </strong> ${row.gameIndex}<br>
    <strong> ✓ Game Name : </strong> ${row.gameName}<br>
    <strong> ✓ Home Team: </strong> ${row.homeTeamName} <br>
    <strong> ✓ Away Team: </strong> ${row.awayTeamName} <br>
    <strong> ✓ Time : </strong> ${unixTimeToString(+row.betEndTime + ONE_HOUR)}  <br>
    <strong> ✓ 무승부 허용여부 : </strong> ${row.drawAllow ? '허용' : '비허용'} <br>
    <br>
    <strong>정말로 실행하시겠습니까?</strong>
    `;

            if (!(await confirmAlert(message))) return;

            const res = await fetch(`${API_BASE_URL}/admin/bet/games`, {
                method: 'PATCH',
                headers: headers(),
                body: JSON.stringify(row)
            });
            await CheckIfExpired(res.status);
            const data = await res.json();
            if (data?.message === 'success') {
                await successAlert('수정하였습니다!');
            } else {
                await errorAlert('수정에 실패하였습니다.');
            }
        }
    } catch (err: any) {
        notificationController.error({
            message: `수정에 실패하였습니다. : ${err.message}`
        });
    }
};

/* Add new game */
export const addGame = async (
    gameIndex: number | undefined,
    gameName: string | undefined,
    homeTeamName: string | undefined,
    awayTeamName: string | undefined,
    date: number,
    time: number,
    drawAllow: boolean | undefined
): Promise<void> => {
    try {
        const nowDateTime = new Date();
        const now = dateToUnixTimeBySeconds(nowDateTime);
        const gameStartTime = dateToUnixTimeBySeconds(parceDateTime(date, time));

        if (gameName === '') {
            notificationController.error({
                message: '경기명을 입력해주세요.'
            });
            return;
        } else if (homeTeamName === '') {
            notificationController.error({
                message: '홈팀명을 입력해주세요.'
            });
            return;
        } else if (awayTeamName === '') {
            notificationController.error({
                message: '원정팀명을 입력해주세요.'
            });
            return;
        } else if (date === 0) {
            notificationController.error({
                message: '경기 시작 날짜를 입력해주세요.'
            });
            return;
        } else if (time === 0) {
            notificationController.error({
                message: '경기 시작 시간을 입력해주세요.'
            });
            return;
        }

        if (getAfterSixHours(nowDateTime) > gameStartTime)
            return notificationController.error({
                message: '경기 시작 시간은 현재 시간보다 6시간 이후여야 합니다.'
            });

        const betStartTime =
            now > gameStartTime - ONE_HOUR * 25 ? now : gameStartTime - ONE_HOUR * 25;

        const body = {
            gameIndex,
            gameName,
            homeTeamName,
            awayTeamName,
            drawAllow,
            betStartTime,
            betEndTime: gameStartTime - ONE_HOUR * 1
        };

        const message = `❗️ 다음과 같이 경기를 추가 합니다 <br>
    <strong> ✓ Game Number : </strong> ${gameIndex}<br>
    <strong> ✓ Game Name : </strong> ${gameName}<br>
    <strong> ✓ Home Team: </strong> ${homeTeamName} <br>
    <strong> ✓ Away Team: </strong> ${awayTeamName} <br>
    <strong> ✓ Time : </strong> ${unixTimeToString(gameStartTime)}  <br>
    <strong> ✓ 무승부 허용여부 : </strong> ${drawAllow ? '허용' : '비허용'} <br>
    <br>
    <strong>정말로 실행하시겠습니까?</strong>
    `;

        if (!(await confirmAlert(message))) return;

        const res = await fetch(`${API_BASE_URL}/admin/bet/games`, {
            method: 'POST',
            headers: headers(),
            body: JSON.stringify(body)
        });

        await CheckIfExpired(res.status);

        const data = await res.json();
        if (data.message === 'success') {
            await successAlert('경기를 추가하였습니다!');
        } else {
            await errorAlert('경기 추가에 실패하였습니다.');
        }
    } catch (err: any) {
        notificationController.error({
            message: `경기 추가에 실패하였습니다. : ${err.message}`
        });
    }
};

export const pressDeleteGameButton = async (
    gameIndex: number,
    betStartTime: number
): Promise<void> => {
    try {
        const message = `
    ${gameIndex}번 게임을 삭제합니다. <br>
    <strong>정말로 삭제하시겠습니까?</strong>
  `;

        if (!(await confirmAlert(message))) return;

        const now = new Date();
        if (now > new Date(betStartTime * 1000 - FIVE_MINUTES))
            return notificationController.error({
                message: '이미 베팅 게임 삭제 가능 시간이 초과되어 삭제할 수 없습니다.'
            });

        const res = await fetch(`${API_BASE_URL}/admin/bet/games`, {
            method: 'DELETE',
            headers: headers(),
            body: JSON.stringify({ gameIndex })
        });
        await CheckIfExpired(res.status);
        const data = await res.json();

        if (data.message === 'success') {
            await successAlert('게임 삭제 성공');
        } else {
            await errorAlert('게임 삭제 실패');
        }
    } catch (err: any) {
        notificationController.error({
            message: `게임 삭제 실패 : ${err.message}`
        });
    }
};

/********* Commission CRUD *********/
/* API for get commission rate */
export const getBetCommission = async (): Promise<{ betCommission: number } & void> => {
    try {
        const res = await fetch(`${API_BASE_URL}/admin/cost/bet`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });
        await CheckIfExpired(res.status);
        return await res.json();
    } catch (err: any) {
        notificationController.error({
            message: `수수료 조회에 실패하였습니다. : ${err.message}`
        });
    }
};

/********** Game Result CRUD *********/

/* When Press Result Insert Button */
export const pressResultInsertButton = async (record: BetTableRow): Promise<void> => {
    if (record.gameIndex && record.betEndTime) {
        const content = `<div align="left">
        ✔️ <strong>경기명</strong> : ${record.gameName} <br>
        ✔️ <strong>팀 정보</strong> : [Home Team]${record.homeTeamName} VS [Away Team]${
            record.awayTeamName
        }<br>
        ✔️ <strong>경기 시간</strong> : ${unixTimeToString(+record.betEndTime + ONE_HOUR)} <br>
        ✔️ <strong>무승부 허용 여부</strong> : ${record.drawAllow ? '허용' : '비허용'}  <br>
        </div>`;

        const inputOptions = new Promise(resolve => {
            setTimeout(() => {
                resolve(
                    'test'
                        ? {
                              0: 'DRAW',
                              1: 'HOME',
                              2: 'AWAY'
                          }
                        : { 1: 'HOME', 2: 'AWAY' }
                );
            }, 100);
        });

        const { value: gameResult } = await Swal.fire({
            position: 'center',
            icon: 'info',
            title: `경기 결과 입력`,
            html: content,
            input: 'radio',
            inputOptions: inputOptions,
            inputValidator: result => (!result as any) && '경기 결과를 골라주세요!',
            showCancelButton: true,
            confirmButtonText: '입력하기',
            confirmButtonColor: Color.mint,
            cancelButtonText: '취소',
            iconColor: Color.pink,
            cancelButtonColor: Color.pink
        });

        if (gameResult) {
            let resultString = '';
            switch (gameResult) {
                case '0':
                    resultString = 'DRAW';
                    break;
                case '1':
                    resultString = 'HOME';
                    break;
                case '2':
                    resultString = 'AWAY';
                    break;
            }
            Swal.fire({
                html: ` ${record.gameIndex}번 경기 결과를 <strong>${resultString}</strong>로 입력하시겠습니까? <br>
            <strong>❗️한번 선택하면 수정할 수 없습니다!</strong>`,
                showCancelButton: true,
                confirmButtonText: 'YES',
                cancelButtonText: 'NO',
                confirmButtonColor: Color.mint,
                cancelButtonColor: Color.pink
            }).then(result => {
                if (result.isConfirmed) {
                    Swal.fire({
                        html: `❗️정말로 <strong>${resultString}</strong>로 입력하시겠습니까?`,
                        showCancelButton: true,
                        confirmButtonText: 'YES',
                        cancelButtonText: 'Cancel',
                        confirmButtonColor: Color.mint,
                        cancelButtonColor: Color.pink
                    }).then(result => {
                        if (result.isConfirmed) {
                            if (record.gameIndex) addResult(+record.gameIndex, gameResult);
                        }
                    });
                }
            });
        }
    }
};

/* Add result of game */
const addResult = async (gameIndex: number, result: number): Promise<void> => {
    try {
        const res = await fetch(`${API_BASE_URL}/admin/bet/result`, {
            method: 'PATCH',
            headers: headers(),
            body: JSON.stringify({
                gameIndex,
                result
            })
        });
        await CheckIfExpired(res.status);
        const data = await res.json();
        if (data.message === 'success') {
            await successAlert('경기 결과 입력 성공');
        } else {
            await errorAlert('경기 결과 입력 실패');
        }
    } catch (err: any) {
        notificationController.error({
            message: `경기 결과 입력 실패 : ${err.message}`
        });
    }
};

export const addChannelId = async (channelId: number): Promise<void> => {
    try {
        if (!channelId)
            return notificationController.error({
                message: '채널 ID를 입력해주세요.'
            });

        const message = `
    <strong>${channelId}</strong>번 채널 ID를 추가합니다. <br>
    <strong>정말로 추가하시겠습니까?</strong>
    `;
        if (!(await confirmAlert(message))) return;

        const res = await fetch(`${API_BASE_URL}/admin/bet/channel-ids`, {
            method: 'POST',
            headers: headers(),
            body: JSON.stringify({ channelId })
        });
        await CheckIfExpired(res.status);
        const data = await res.json();
        if (data.message === 'success') {
            await successAlert('채널 ID 추가 성공');
        } else {
            await errorAlert('채널 ID 추가 실패');
        }
    } catch (err: any) {
        notificationController.error({
            message: `채널 ID 추가 실패 : ${err.message}`
        });
    }
};

export const getChannelIds = async (): Promise<string[]> => {
    try {
        const res = await fetch(`${API_BASE_URL}/admin/bet/channel-ids`, {
            method: 'GET',
            headers: headers()
        });
        await CheckIfExpired(res.status);
        const data = await res.json();

        return data;
    } catch (err: any) {
        notificationController.error({
            message: `채널 ID 가져오기 실패 : ${err.message}`
        });
        return [];
    }
};

export const deleteChannelId = async (channelId: string): Promise<void> => {
    try {
        const message = `
                 <strong>${channelId}</strong>번 채널 ID를 삭제합니다. <br>
                  <strong>정말로 삭제하시겠습니까?</strong>
                      `;

        if (!(await confirmAlert(message))) return;

        const res = await fetch(`${API_BASE_URL}/admin/bet/channel-ids`, {
            method: 'DELETE',
            headers: headers(),
            body: JSON.stringify({ channelId })
        });

        await CheckIfExpired(res.status);
        const data = await res.json();
        if (data.message === 'success') {
            await successAlert('채널 ID 삭제 성공');
        } else {
            await errorAlert('채널 ID 삭제 실패');
        }
    } catch (err: any) {
        notificationController.error({
            message: `채널 ID 삭제 실패 : ${err.message}`
        });
    }
};

/*********** Helper Function ************/
export const parceResult = (result: number, record: BetTableRow): string | void => {
    switch (result) {
        case 1:
            return 'HOME';
        case 0:
            return 'DRAW';
        case 2:
            return 'AWAY';
        case -1:
            const now = dateToUnixTimeBySeconds(new Date());

            if (record.betEndTime && record.betStartTime) {
                const gameStartTime = +record.betEndTime + ONE_HOUR;
                const betStartTime = +record.betStartTime;
                const betEndTime = +record.betEndTime;

                if (gameStartTime < now) return 'needToInsertResult';
                else if (betEndTime < now) return '경기 시작 전';
                else if (betStartTime < now) return '배팅 중';
                else return '배팅 전';
            }
    }
};

export const caculateBetTime = (
    gameStartTime: number
): { betStartTime: number; betEndTime: number } => {
    const betStartTime = gameStartTime - 25 * ONE_HOUR;
    const betEndTime = gameStartTime - ONE_HOUR;
    return { betStartTime, betEndTime };
};
