import { notificationController } from '@app/controllers/notificationController';
import { CheckIfExpired } from './common.api';
import { API_BASE_URL, ONE_DAY } from '@app/consts/consts';
import { getYYYYMMDDBySeconds } from '@app/utils/utils';

export const getDailyBotData = async (
    startTime: number,
    endTime: number
): Promise<
    | [
          {
              reason: number;
              awd: number;
              earn: number;
              burn: number;
              cnt: number;
              reasonGroup: number;
          }[],
          {
              newMembers: number;
              lostMembers: number;
              totalMembers: number;
              totalBoosters: number;
          }[],
          {
              reason: number;
              count: number;
              get: number;
              lost: number;
          }[]
      ]
    | [][]
    | void
> => {
    try {
        const now = getYYYYMMDDBySeconds(new Date());
        const isFiltered = +startTime * +endTime;

        if (isFiltered) {
            if (+startTime + ONE_DAY * 30 < +endTime) {
                notificationController.error({
                    message: `조회기간을 30일 이내로 해주세요.`
                });
                return [[], [], []];
            }

            if (+endTime > now - ONE_DAY) {
                notificationController.error({
                    message: `조회는 오늘로부터 1일 전까지 가능합니다.`
                });
                return [[], [], []];
            }
        }

        const res = await fetch(
            `${API_BASE_URL}/admin/rawdata?startTime=${startTime}&endTime=${endTime}`,
            {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            }
        );

        await CheckIfExpired(res.status);

        notificationController.success({
            message: `Data 조회에 성공했습니다.`
        });
        const data = await res.json();
        return data;
    } catch (err: any) {
        notificationController.error({
            message: `botData 조회에 실패했습니다. : ${err.message}`
        });
        return [[], [], []];
    }
};

export const parseAwdReason = (reason: number) => {
    switch (reason) {
        case 1:
            return 'Chat';
        case 2:
            return 'SNS Twitter';
        case 3:
            return 'Referral';
        case 4:
            return 'Attend';
        case 5:
            return 'MysteryBox';
        case 6:
            return 'Give Admin';
        case 7:
            return 'Betting';
        case 8:
            return '2 Weeks Referral';
        case 9:
            return 'Mint';
        case 10:
            return 'Wrong Topic';
        case 11:
            return 'No Essential Tag';
        case 12:
            return 'No Essential Picture';
        case 13:
            return 'Different Post';
        case 14:
            return 'Private Account';
        case 15:
            return 'Fake Post';
        case 16:
            return 'Twitter Retweet And Like';
        case 17:
            return 'No Essential Content';
        case 18:
            return 'Abusing';
        case 19:
            return 'No User Content';
        case 20:
            return 'No User Tag';
        case 21:
            return 'Coin Flip Game';
        default:
            return '';
    }
};

export const parseMysteryboxReason = (reason: number) => {
    switch (reason) {
        case 1:
            return 'Attend';
        case 2:
            return 'Buy or Open MysteryBox';
        case 3:
            return 'Admin';
        default:
            return '';
    }
};

export const awdDataList = () => {
    return [
        {
            reasonGroup: 0,
            total: 'TOTAL',
            mainReason: '',
            detailReason: '',
            awd: 0,
            earn: 0,
            burn: 0,
            cnt: 0
        },
        {
            reasonGroup: -1,
            mainReason: `Chat`,
            detailReason: 1,
            awd: 0,
            earn: 0,
            burn: 0,
            cnt: 0
        },
        {
            reasonGroup: 1,
            mainReason: `Referral`,
            detailReason: ``,
            awd: 0,
            earn: 0,
            burn: 0,
            cnt: 0
        },
        {
            reasonGroup: -1,
            mainReason: ``,
            detailReason: 3,
            awd: 0,
            earn: 0,
            burn: 0,
            cnt: 0
        },
        {
            reasonGroup: -1,
            mainReason: ``,
            detailReason: 8,
            awd: 0,
            earn: 0,
            burn: 0,
            cnt: 0
        },
        {
            reasonGroup: -1,
            mainReason: `Attend`,
            detailReason: 4,
            awd: 0,
            earn: 0,
            burn: 0,
            cnt: 0
        },
        {
            reasonGroup: -1,
            mainReason: parseAwdReason(5),
            detailReason: 5,
            awd: 0,
            earn: 0,
            burn: 0,
            cnt: 0
        },
        {
            reasonGroup: 2,
            mainReason: `Admin`,
            detailReason: ``,
            awd: 0,
            earn: 0,
            burn: 0,
            cnt: 0
        },
        {
            reasonGroup: -1,
            mainReason: ``,
            detailReason: 6,
            awd: 0,
            earn: 0,
            burn: 0,
            cnt: 0
        },
        {
            reasonGroup: -1,
            mainReason: ``,
            detailReason: 10,
            awd: 0,
            earn: 0,
            burn: 0,
            cnt: 0
        },
        {
            reasonGroup: -1,
            mainReason: ``,
            detailReason: 11,
            awd: 0,
            earn: 0,
            burn: 0,
            cnt: 0
        },
        {
            reasonGroup: -1,
            mainReason: ``,
            detailReason: 12,
            awd: 0,
            earn: 0,
            burn: 0,
            cnt: 0
        },
        {
            reasonGroup: -1,
            mainReason: ``,
            detailReason: 13,
            awd: 0,
            earn: 0,
            burn: 0,
            cnt: 0
        },
        {
            reasonGroup: -1,
            mainReason: ``,
            detailReason: 14,
            awd: 0,
            earn: 0,
            burn: 0,
            cnt: 0
        },
        {
            reasonGroup: -1,
            mainReason: ``,
            detailReason: 15,
            awd: 0,
            earn: 0,
            burn: 0,
            cnt: 0
        },
        {
            reasonGroup: -1,
            mainReason: ``,
            detailReason: 17,
            awd: 0,
            earn: 0,
            burn: 0,
            cnt: 0
        },
        {
            reasonGroup: -1,
            mainReason: ``,
            detailReason: 18,
            awd: 0,
            earn: 0,
            burn: 0,
            cnt: 0
        },
        {
            reasonGroup: -1,
            mainReason: ``,
            detailReason: 19,
            awd: 0,
            earn: 0,
            burn: 0,
            cnt: 0
        },
        {
            reasonGroup: -1,
            mainReason: ``,
            detailReason: 20,
            awd: 0,
            earn: 0,
            burn: 0,
            cnt: 0
        },
        {
            reasonGroup: -1,
            mainReason: parseAwdReason(7),
            detailReason: 7,
            awd: 0,
            earn: 0,
            burn: 0,
            cnt: 0
        },
        {
            reasonGroup: -1,
            mainReason: parseAwdReason(9),
            detailReason: 9,
            awd: 0,
            earn: 0,
            burn: 0,
            cnt: 0
        },
        {
            reasonGroup: -1,
            mainReason: parseAwdReason(16),
            detailReason: 16,
            awd: 0,
            earn: 0,
            burn: 0,
            cnt: 0
        },
        {
            reasonGroup: -1,
            mainReason: parseAwdReason(2),
            detailReason: 2,
            awd: 0,
            earn: 0,
            burn: 0,
            cnt: 0
        },
        {
            reasonGroup: -1,
            mainReason: parseAwdReason(21),
            detailReason: 21,
            awd: 0,
            earn: 0,
            burn: 0,
            cnt: 0
        }
    ];
};

export const mysteryboxDataList = () => {
    return [
        {
            total: 'TOTAL',
            reason: '',
            count: 0,
            get: 0,
            lost: 0
        },
        {
            reason: 1,
            count: 0,
            get: 0,
            lost: 0
        },
        {
            reason: 2,
            count: 0,
            get: 0,
            lost: 0
        },
        {
            reason: 3,
            count: 0,
            get: 0,
            lost: 0
        }
    ];
};
