import { Color } from '@app/styles/themes/light/lightTheme';
import Swal from 'sweetalert2';

/* Success */
export const successAlert = async (message: string): Promise<void> => {
    await Swal.fire({
        icon: 'success',
        iconColor: Color.mint,
        titleText: `✨ Success!`,
        html: message,
        showConfirmButton: false,
        timer: 1000
    }).then(() => {
        location.reload();
    });
};

/* Error */
export const errorAlert = async (message: string): Promise<void> => {
    await Swal.fire({
        icon: 'error',
        iconColor: Color.pink,
        html: message,
        titleText: '❗️ Error!',
        showConfirmButton: false,
        timer: 2000
    }).then(() => {
        location.reload();
    });
};

/* Confirm */
export const confirmAlert = async (message: string): Promise<boolean> => {
    const result = await Swal.fire({
        icon: 'warning',
        iconColor: Color.pink,
        titleText: '🚨 Warning!',
        html: message,
        showCancelButton: true,
        confirmButtonText: 'YES',
        cancelButtonText: 'Cancel',
        confirmButtonColor: Color.mint,
        cancelButtonColor: Color.pink
    });
    return result.isConfirmed;
};

/* Expired */
export const expiredAlert = async (message: string, navigate: any): Promise<void> => {
    await Swal.fire({
        icon: 'error',
        iconColor: Color.pink,
        html: message,
        titleText: '❗️ 토큰 만료!',
        showConfirmButton: true,
        confirmButtonText: '로그인하러 가기',
        confirmButtonColor: Color.mint
    }).then(isConfirmed => {
        if (isConfirmed) navigate('/auth/login');
    });
};
