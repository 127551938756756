import { headers, validityCheck, badRequest, CheckIfExpired } from './common.api';
import { confirmAlert, errorAlert, successAlert } from './Alert.api';
import { notificationController } from '@app/controllers/notificationController';
import { API_BASE_URL, ONE_DAY } from '@app/consts/consts';

/* Indexing of Reason */
export const adminReasonList = [
    {
        index: 1,
        reason: `Abusing`
    },
    {
        index: 2,
        reason: `Event`
    },
    {
        index: 3,
        reason: `Error Compensation`
    },
    {
        index: 5,
        reason: `Profile Change`
    },
    {
        index: 6,
        reason: `Hold Profile 1 Month`
    },
    {
        index: 7,
        reason: `Promotion`
    },
    {
        index: 8,
        reason: `Challenge`
    },
    {
        index: 9,
        reason: `Sport Game`
    },
    {
        index: 10,
        reason: `Market Place`
    },
    {
        index: 11,
        reason: `Mission Mining`
    },
    {
        index: 4,
        reason: `ETC`
    }
];

export const reasonList = [
    {
        index: 1,
        reason: `채팅 입력`
    },
    {
        index: 2,
        reason: `SNS`
    },
    {
        index: 3,
        reason: `초대`
    },
    {
        index: 4,
        reason: `출석`
    },
    {
        index: 5,
        reason: `미스테리 박스`
    },
    {
        index: 6,
        reason: `어드민`
    },
    {
        index: 7,
        reason: `배팅게임`
    },
    {
        index: 8,
        reason: `초대 2주 보상`
    },
    {
        index: 9,
        reason: `민팅`
    },
    {
        index: 10,
        reason: `잘못된 토픽 (-200)`
    },
    {
        index: 11,
        reason: `잘못된 태그 (-200)`
    },
    {
        index: 12,
        reason: `사진 없음 (-200)`
    },
    {
        index: 13,
        reason: `다른 게시물 (-300)`
    },
    {
        index: 14,
        reason: `허위 계정 (-600)`
    },
    {
        index: 15,
        reason: `허위 게시물 (-600)`
    },
    {
        index: 16,
        reason: `트위터 리트윗 및 좋아요`
    },
    {
        index: 17,
        reason: `내용 없음 (-200)`
    },
    {
        index: 18,
        reason: `각종 어뷰징 (-600)`
    },
    {
        index: 19,
        reason: `유저내용 미작성 (-200)`
    },
    {
        index: 20,
        reason: `유저태그 미작성 (-200)`
    },
    {
        index: 21,
        reason: `동전 던지기 게임`
    }
];

/* Get AWD Log */
export const getAwdLog = async (): Promise<string[] | void> => {
    try {
        const res = await fetch(`${API_BASE_URL}/admin/log/awds`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });
        await CheckIfExpired(res.status);
        const data = await res.json();
        return data;
    } catch (err: any) {
        notificationController.error({
            message: `AWD 로그를 불러오는데 실패했습니다. ${err.message}`
        });
    }
};

/* Get AWD Amount by specific user */
export const getAwdAmount = async (
    userId: string,
    userName: string,
    startTime: number,
    endTime: number
): Promise<{
    statusCode?: string;
    userId: string;
    amount: number;
    logs:
        | {
              date: string;
              awd: number;
              reason: number;
          }[];
} | void> => {
    try {
        let data = userId.trim();
        let dataName = 'userId';

        if (userId === '') {
            data = userName;
            dataName = 'userName';
        }

        const isFiltered = +startTime * +endTime;

        if (isFiltered) {
            if (+startTime + ONE_DAY * 30 < +endTime) {
                notificationController.error({
                    message: `조회기간을 30일 이내로 해주세요.`
                });
                return;
            }
        }

        const res = await fetch(
            `${API_BASE_URL}/admin/awds?${dataName}=${encodeURIComponent(
                data
            )}&startTime=${startTime}&endTime=${endTime}
            `,
            {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            }
        );
        const result = await res.json();
        await CheckIfExpired(res.status);
        if (badRequest(result)) errorAlert(`AWD를 불러오는데 실패했습니다. `);
        return result;
    } catch (err: any) {
        notificationController.error({
            message: `AWD를 불러오는데 실패했습니다. ${err.message}`
        });
    }
};

/* Update AWD Amount */
export const pressGiveAwdButton = async (
    userId: string,
    amount: number,
    reason: number
): Promise<void> => {
    try {
        if (!validityCheck(amount, reason)) return;

        const message = `
  ❗️ <strong> ${+amount} </strong> AWD를 ${amount > 0 ? '지급' : '차감'}합니다. <br>
  <strong> ID :  ${userId} </strong> <br>
  <strong> 사유 : ${parceAdminReason(reason)} </strong> <br><br>
  입력값을 확인해주세요!`;

        if (!(await confirmAlert(message))) return;
        const res = await fetch(`${API_BASE_URL}/admin/awds/${userId}`, {
            method: 'PATCH',
            headers: headers(),
            body: JSON.stringify({ amount, reason })
        });
        await CheckIfExpired(res.status);
        const data = await res.json();
        if (badRequest(data)) return;
        if (data.amount) {
            await successAlert('AWD 부여/차감에 성공하였습니다.');
        } else {
            await errorAlert('AWD 부여/차감에 실패하였습니다.');
        }
    } catch (err: any) {
        notificationController.error({
            message: `AWD를 부여/차감하는 데 실패했습니다. ${err.message}`
        });
    }
};

/* Parce numeric reason to string (admin) */
export const parceAdminReason = (reason: number): string => {
    const result = adminReasonList.find(item => item.index === reason);
    if (!result) return 'NaN';
    return result.reason;
};

/* Parce numeric reason to string (general) */
export const parceReason = (reason: number): string => {
    const result = reasonList.find(item => item.index === reason);
    if (!result) return 'NaN';
    return result.reason;
};
