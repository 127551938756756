import { createSlice } from '@reduxjs/toolkit';

export type InitialState = {
    logs: string[];
};

export const initialState: InitialState = {
    logs: []
};

export const logsSlice = createSlice({
    name: 'logs',
    initialState,
    reducers: {
        addLog: (state, action) => {
            state.logs = action.payload;
        }
    }
});

export const { addLog } = logsSlice.actions;

export default logsSlice.reducer;
