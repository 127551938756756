import { useState } from 'react';
import { MembersTableRow } from 'api/table.api';
import { Table } from 'components/common/Table/Table';
import { ColumnsType } from 'antd/es/table';
import { Color } from '@app/styles/themes/light/lightTheme';

export const MembersTable = (botData: any) => {
    const [tableData, setTableData] = useState<{
        data: MembersTableRow[];
        loading: boolean;
    }>({
        data: [],
        loading: false
    });

    const columns: ColumnsType<MembersTableRow> = [
        {
            title: `New Members`,
            dataIndex: `newMembers`,
            render: (newMembers: number) => {
                return +newMembers === 0 ? (
                    <div
                        style={{
                            color: Color.darkGray
                        }}
                    >
                        -
                    </div>
                ) : (
                    <div
                        style={{
                            color: Color.darkMint,
                            fontWeight: `bold`
                        }}
                    >
                        +{newMembers}
                    </div>
                );
            },
            width: `5%`
        },
        {
            title: `Lost Members`,
            dataIndex: `lostMembers`,
            render: (lostMembers: number) => {
                return +lostMembers === 0 ? (
                    <div
                        style={{
                            color: Color.darkGray
                        }}
                    >
                        -
                    </div>
                ) : (
                    <div
                        style={{
                            color: Color.pink,
                            fontWeight: `bold`
                        }}
                    >
                        -{lostMembers}
                    </div>
                );
            },
            width: `5%`
        },
        {
            title: `Total Members`,
            dataIndex: `totalMembers`,
            render: (totalMembers: number) => {
                return +totalMembers === 0 ? '-' : totalMembers;
            },
            width: `5%`
        },
        {
            title: `Total Boosters`,
            dataIndex: `totalBoosters`,
            render: (totalBoosters: number) => {
                return +totalBoosters === 0 ? '-' : totalBoosters;
            },
            width: `5%`
        }
    ];

    return (
        <Table
            columns={columns}
            dataSource={botData.botData}
            loading={tableData.loading}
            scroll={{ x: 800 }}
            pagination={false}
        />
    );
};
