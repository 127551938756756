import { useState } from 'react';
import { MysteryboxTableRow } from 'api/table.api';
import { Table } from 'components/common/Table/Table';
import { ColumnsType } from 'antd/es/table';
import { parseMysteryboxReason } from '@app/api/\bpageApi/dataManage.api';
import { Color } from '@app/styles/themes/light/lightTheme';

export const MysteryboxTable = (botData: any) => {
    const [tableData, setTableData] = useState<{
        data: MysteryboxTableRow[];
        loading: boolean;
    }>({
        data: [],
        loading: false
    });

    const columns: ColumnsType<MysteryboxTableRow> = [
        {
            title: `Reason`,
            dataIndex: `total`,
            width: `5%`,
            render: (reason: string) => {
                return <div style={{ fontWeight: `bold` }}>{reason}</div>;
            }
        },
        {
            title: ``,
            dataIndex: `reason`,
            render: (reason: string) => {
                return parseMysteryboxReason(+reason);
            },
            width: `10%`
        },
        {
            title: `Count`,
            dataIndex: `count`,
            width: `10%`,
            render: count => {
                return +count === 0 ? (
                    <div
                        style={{
                            color: Color.darkGray
                        }}
                    >
                        -
                    </div>
                ) : +count > 0 ? (
                    <div
                        style={{
                            color: Color.darkMint,
                            fontWeight: `bold`
                        }}
                    >
                        +{count}
                    </div>
                ) : (
                    <div
                        style={{
                            color: Color.pink,
                            fontWeight: `bold`
                        }}
                    >
                        {count}
                    </div>
                );
            }
        },
        {
            title: `User Got`,
            dataIndex: `get`,
            width: `10%`,
            render: get => {
                return (
                    <div style={{ color: Color.darkMint }}> {+get === 0 ? '-' : `+${+get}`} </div>
                );
            }
        },
        {
            title: `User Used or Lost`,
            dataIndex: `lost`,
            width: `10%`,
            render: lost => {
                return <div style={{ color: Color.pink }}> {+lost === 0 ? '-' : +lost} </div>;
            }
        }
    ];

    return (
        <Table
            columns={columns}
            dataSource={botData.botData}
            loading={tableData.loading}
            scroll={{ x: 800 }}
            pagination={false}
        />
    );
};
