interface DefaultPadding {
    mobile: [number, number];
    tablet: [number, number];
    desktop: [number, number];
}

export const defaultPaddings: DefaultPadding = {
    mobile: [30, 16],
    tablet: [40, 30],
    desktop: [25, 25]
};
