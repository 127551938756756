import React from 'react';
import { Col, Row } from 'antd';
import { HeaderFullscreen } from '../components/HeaderFullscreen/HeaderFullscreen';
import * as S from '../Header.styles';
import { Button } from '@app/components/common/buttons/Button/Button';
import { logOut } from '@app/api/\bpageApi/sign.api';
import { useNavigate } from 'react-router-dom';
import { ShowingAccountHeader } from '@app/components/common/ShowingAccountHeader/ShowingAcountHeader';

interface DesktopHeaderProps {
    isTwoColumnsLayout: boolean;
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({ isTwoColumnsLayout }) => {
    const navigate = useNavigate();
    const email = localStorage.getItem('account');
    const pressLogOut = async () => {
        if (await logOut()) {
            navigate('/auth/login');
        }
    };

    const leftSide = (
        <>
            <Col lg={10} xxl={8}>
                <ShowingAccountHeader>{email}</ShowingAccountHeader>
            </Col>
        </>
    );

    return (
        <Row justify="space-between" align="middle">
            {leftSide}

            <S.ProfileColumn xl={8} xxl={7} $isTwoColumnsLayout={isTwoColumnsLayout}>
                <Row align="middle" justify="end" gutter={[10, 10]}>
                    <Col>
                        <Row gutter={[{ xxl: 10 }, { xxl: 10 }]}>
                            <Col>
                                <HeaderFullscreen />
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Button onClick={() => pressLogOut()}>Log Out</Button>
                    </Col>
                </Row>
            </S.ProfileColumn>
        </Row>
    );
};
