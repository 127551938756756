import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import {
    setAwayTeamName,
    setDate,
    setGameName,
    setHomeTeamName,
    setTime
} from '@app/store/slices/betSlice';
import {
    setChatPoint,
    setMiningPoint,
    setInviteRewardPoint,
    setInvite14daysPoint,
    setInviteMaxCount,
    setGmInviteCode,
    setMysteryBoxOpenPoint,
    setMysteryBoxBuyPoint,
    setMessageRewardDelayTime,
    setMysteryBoxBuyDelayTime,
    setTwitterRetweetLikeReward,
    setBetCommission
} from '@app/store/slices/eventPointSlice';

/* Event Point */
export const useEventPointStateList = (): {
    eventPointStateList: {
        state: number | string | undefined;
        func: any;
        name: string;
        type: string;
        dataName: string;
        key: string;
        reason: number;
    }[];
} => {
    const dispatch = useAppDispatch();

    const eventPointStateList = [
        {
            state: useAppSelector(state => state.eventPoint.chatPoint),
            func: (e: React.ChangeEvent<HTMLInputElement>) => dispatch(setChatPoint(e)),
            name: '채팅 포인트',
            type: 'number',
            dataName: 'messageRewards',
            key: 'msgeventpoint',
            reason: 0
        },
        {
            state: useAppSelector(state => state.eventPoint.miningPoint),
            func: (e: React.ChangeEvent<HTMLInputElement>) => dispatch(setMiningPoint(e)),
            name: '마이닝 포인트',
            type: 'number',
            dataName: 'snsMiningRewards',
            key: 'snseventpoint',
            reason: 1
        },
        {
            state: useAppSelector(state => state.eventPoint.inviteRewardPoint),
            func: (e: React.ChangeEvent<HTMLInputElement>) => dispatch(setInviteRewardPoint(e)),
            name: '초대 포인트',
            type: 'number',
            dataName: 'inviteRewards',
            key: 'refeventpoint',
            reason: 2
        },
        {
            state: useAppSelector(state => state.eventPoint.invite14daysPoint),
            func: (e: React.ChangeEvent<HTMLInputElement>) => dispatch(setInvite14daysPoint(e)),
            name: '초대 14주 포인트',
            type: 'number',
            dataName: 'inviteAfter14daysRewards',
            key: 'refpartnerpoint',
            reason: 3
        },
        {
            state: useAppSelector(state => state.eventPoint.inviteMaxCount),
            func: (e: React.ChangeEvent<HTMLInputElement>) => dispatch(setInviteMaxCount(e)),
            name: '초대 최대 횟수',
            type: 'number',
            dataName: 'inviteLimit',
            key: 'ref:max:count',
            reason: 4
        },
        {
            state: useAppSelector(state => state.eventPoint.gmInviteCode),
            func: (e: React.ChangeEvent<HTMLInputElement>) => dispatch(setGmInviteCode(e)),
            name: 'GM 초대 코드',
            type: 'text',
            dataName: 'gmInviteCode',
            key: 'gminvite',
            reason: 5
        },
        {
            state: useAppSelector(state => state.eventPoint.messageRewardDelayTime),
            func: (e: React.ChangeEvent<HTMLInputElement>) =>
                dispatch(setMessageRewardDelayTime(e)),
            name: '메시지 포인트 지급 딜레이',
            type: 'number',
            dataName: 'messageLatencyTime',
            key: 'msg:latency:time',
            reason: 8
        },
        {
            state: useAppSelector(state => state.eventPoint.mysteryBoxBuyDelayTime),
            func: (e: React.ChangeEvent<HTMLInputElement>) =>
                dispatch(setMysteryBoxBuyDelayTime(e)),
            name: '미스테리 박스 구매 딜레이',
            type: 'number',
            dataName: 'mysteryBoxBuyLatencyTime',
            key: 'mysterybox:latency:time',
            reason: 9
        },
        {
            state: useAppSelector(state => state.eventPoint.twitterRetweetLikeReward),
            func: (e: React.ChangeEvent<HTMLInputElement>) =>
                dispatch(setTwitterRetweetLikeReward(e)),
            name: '트위터 리트윗 좋아요 포인트',
            type: 'number',
            dataName: 'twitterRetweetAndLikeRewards',
            key: 'twitterinteractpoint',
            reason: 10
        }
    ];

    return { eventPointStateList: eventPointStateList };
};

/* MysteryBox */
export const useMysteryBoxStateList = (): {
    mysteryBoxStateList: {
        state: number | undefined;
        func: any;
        name: string;
        type: string;
        dataName: string;
        key: string;
        reason: number;
    }[];
} => {
    const dispatch = useAppDispatch();
    const mysteryBoxStateList = [
        {
            state: useAppSelector(state => state.eventPoint.mysteryBoxOpenPoint),
            func: (e: React.ChangeEvent<HTMLInputElement>) => dispatch(setMysteryBoxOpenPoint(e)),
            name: '오픈 포인트',
            type: 'number',
            dataName: 'openMysteryBox',
            key: 'mysteryboxcost',
            reason: 6
        },
        {
            state: useAppSelector(state => state.eventPoint.mysteryBoxBuyPoint),
            func: (e: React.ChangeEvent<HTMLInputElement>) => dispatch(setMysteryBoxBuyPoint(e)),
            name: '구입 포인트',
            type: 'number',
            dataName: 'buyMysteryBox',
            key: 'mysteryboxprice',
            reason: 7
        }
    ];
    return { mysteryBoxStateList: mysteryBoxStateList };
};

/* Bet */
export const useBetStateList = (): {
    betStateList: {
        state: number | undefined;
        func: any;
        name: string;
        type: string;
        dataName: string;
        key: string;
        reason: number;
    }[];
} => {
    const dispatch = useAppDispatch();
    const betStateList = [
        {
            state: useAppSelector(state => state.eventPoint.betCommission),
            func: (e: React.ChangeEvent<HTMLInputElement>) => dispatch(setBetCommission(e)),
            name: '베팅 수수료',
            type: 'number',
            dataName: 'betCommission',
            key: 'betcommission',
            reason: 11
        }
    ];
    return { betStateList: betStateList };
};

/* Games */
export const useGamesStateList = (): {
    gamesStateList: { name: string; type: string; func: any; placeholder: string }[];
} => {
    const dispatch = useAppDispatch();
    const gamesStateList = [
        {
            name: '경기명',
            type: 'text',
            func: (e: React.ChangeEvent<HTMLInputElement>) => dispatch(setGameName(e)),
            placeholder: '경기명을 입력해주세요'
        },
        {
            name: 'Home Team 이름',
            type: 'text',
            func: (e: React.ChangeEvent<HTMLInputElement>) => dispatch(setHomeTeamName(e)),
            placeholder: 'Home Team 이름을 입력해주세요'
        },
        {
            name: 'Away Team 이름',
            type: 'text',
            func: (e: React.ChangeEvent<HTMLInputElement>) => dispatch(setAwayTeamName(e)),
            placeholder: 'Away Team 이름을 입력해주세요'
        },
        {
            name: '경기 일정',
            type: 'number',
            func: (e: React.ChangeEvent<HTMLInputElement>) => dispatch(setDate(e)),
            placeholder: 'yyyymmdd 형식 : 23년 4월 30일 ➡️ 20230430'
        },
        {
            name: '경기 시간',
            type: 'number',
            func: (e: React.ChangeEvent<HTMLInputElement>) => dispatch(setTime(e)),
            placeholder: 'hhmm 형식 : 오후 2시 30분 ➡️ 1430 or hh 형식 : 오후 2시 00분 ➡️ 14'
        }
    ];
    return { gamesStateList: gamesStateList };
};
