import { notificationController } from '@app/controllers/notificationController';
import { confirmAlert } from './Alert.api';
import { API_BASE_URL } from '@app/consts/consts';

/* Log In */
export const login = async (email: string, password: string): Promise<void | boolean> => {
    try {
        const res = await fetch(`${API_BASE_URL}/admin/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password })
        });
        const data = await res.json();
        if (data.message === 'success') {
            localStorage.setItem('token', data.token);
            return true;
        } else {
            notificationController.error({
                message: data['message']
            });
        }
    } catch (err: any) {
        notificationController.error({
            message: `로그인에 실패했습니다. : ${err.message}`
        });
    }
};

/* Log Out */
export const logOut = async (): Promise<boolean | void> => {
    if (!(await confirmAlert('로그아웃 하시겠습니까?'))) return;
    localStorage.removeItem('token');
    localStorage.removeItem('account');
    return true;
};

/* Sign Up */
export const signUp = async (
    email: string,
    password: string,
    code: string
): Promise<void | boolean> => {
    try {
        if (!email.includes('@anotherworld.game')) {
            notificationController.error({
                message: 'anotherworld.game 이메일만 가입이 가능합니다'
            });
            return false;
        }
        if (password.length < 6 || password.length > 12) {
            notificationController.error({
                message: '비밀번호는 6자 이상 12자 이하로 설정해주세요'
            });
            return false;
        }
        await fetch(`${API_BASE_URL}/admin/register`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password, code })
        })
            .then(res => res.json())
            .then(data => {
                switch (data['message']) {
                    case '어드민 가입이 완료되었습니다':
                        notificationController.success({
                            message: data['message']
                        });
                        return true;
                    default:
                        notificationController.error({
                            message: data['message']
                        });
                        return false;
                }
            });
    } catch (err: any) {
        notificationController.error({
            message: `회원가입에 실패했습니다. : ${err.message}`
        });
    }
};
