import { ThemeType } from '@app/interfaces/interfaces';
import { createSlice, createAction, PrepareAction } from '@reduxjs/toolkit';

interface ThemeState {
    theme: ThemeType;
}

const initialState: ThemeState = {
    theme: 'light'
};

export const setTheme = createAction<PrepareAction<ThemeType>>(
    'theme/setTheme',
    (theme: ThemeType) => {
        return {
            payload: theme
        };
    }
);

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(setTheme, (state, action) => {
            state.theme = action.payload;
        });
    }
});

export default themeSlice.reducer;
