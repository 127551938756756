import { Color } from '@app/styles/themes/light/lightTheme';
import styled from 'styled-components';

export const Wrapper = styled.div`
    background-color: ${Color.white};
    padding: 1rem;
    align-items: center;
    display: flex;
    border-radius: 2rem;
    box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.1);
    border: 0.3px solid ${Color.lightGray};
    width: 550px;
    height: 50px;
`;

export const Prefix = styled.div`
    color: ${Color.black};
    font-weight: bold;
`;

export const Title = styled.div`
    color: ${Color.darkGray};
    margin-left: 0.5rem;
`;
