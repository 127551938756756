import { useEffect, useState, useCallback } from 'react';
import { TablePaginationConfig } from 'antd';
import { getBasicTableData, Pagination, UserAwdTableRow } from 'api/table.api';
import { Table } from 'components/common/Table/Table';
import { ColumnsType } from 'antd/es/table';
import { useMounted } from '@app/hooks/useMounted';
import { parceReason, reasonList } from '@app/api/\bpageApi/awdManage.api';
import { Color } from '@app/styles/themes/light/lightTheme';
import { getYYYYMMDDHHII, uctToKst } from '@app/utils/utils';

const initialPagination: Pagination = {
    current: 1,
    pageSize: 20
};

export const UserAwdTable = (userLogs: any) => {
    const logs: { date: string }[] = [];
    Object.keys(userLogs).forEach(key => {
        logs.push(userLogs[key]);
    });

    const [tableData, setTableData] = useState<{
        data: UserAwdTableRow[];
        pagination: Pagination;
        loading: boolean;
    }>({
        data: [],
        pagination: initialPagination,
        loading: false
    });
    const { isMounted } = useMounted();

    const fetch = useCallback(
        (pagination: Pagination) => {
            setTableData(tableData => ({ ...tableData, loading: true }));
            getBasicTableData(pagination).then(res => {
                if (isMounted.current) {
                    setTableData({
                        data: [],
                        pagination: res.pagination,
                        loading: false
                    });
                }
            });
        },
        [isMounted]
    );

    useEffect(() => {
        fetch(initialPagination);
    }, [fetch]);

    const handleTableChange = (pagination: TablePaginationConfig) => {
        fetch(pagination);
    };

    const filterList = [];
    for (let i = 0; i < reasonList.length; i++) {
        filterList.push({
            text: reasonList[i].reason,
            value: reasonList[i].index
        });
    }

    const columns: ColumnsType<UserAwdTableRow> = [
        {
            title: `Date`,
            dataIndex: `date`,
            width: `10%`,
            sorter: (a: UserAwdTableRow, b: UserAwdTableRow) =>
                (a.date as any).localeCompare(b.date),
            render: (date: number) => {
                const kst = uctToKst(new Date(date * 1_000));
                return getYYYYMMDDHHII(kst);
            }
        },
        {
            title: `awd`,
            dataIndex: `awd`,
            width: `7%`,
            render: (awd: number) => {
                return (
                    <div
                        style={{
                            color: awd > 0 ? Color.tintMint : Color.pink
                        }}
                    >
                        {awd > 0 ? `+${awd}` : awd}
                    </div>
                );
            },
            sorter: (a: UserAwdTableRow, b: UserAwdTableRow) => (a.awd as any) - (b.awd as any)
        },
        {
            title: `Reason`,
            dataIndex: `reason`,
            width: `13%`,
            render: (reason: number) => {
                return parceReason(+reason);
            },
            filterMode: 'menu',
            filters: filterList,
            onFilter: (value: string | number | boolean, record: UserAwdTableRow) => {
                console.log(`record.reason :`, record.reason, `value is `, value);
                return record.reason === value;
            },
            sorter: (a: UserAwdTableRow, b: UserAwdTableRow) => {
                return (a.reason as any) - (b.reason as any);
            }
        }
    ];

    return (
        <Table
            columns={columns}
            dataSource={logs}
            loading={tableData.loading}
            onChange={handleTableChange}
            scroll={{ x: 800 }}
        />
    );
};
