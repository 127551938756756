import React, { useEffect, useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Card } from '@app/components/common/Card/Card';
import {
    awdDataList,
    getDailyBotData,
    mysteryboxDataList
} from '@app/api/\bpageApi/dataManage.api';
import { Title } from '@app/components/common/Title/Title';
import { useNavigate } from 'react-router-dom';
import { isValidToken } from '@app/api/\bpageApi/common.api';
import { DailyBotDataTable } from '@app/components/tables/DailyBotDataTable/DailyBotDataTable';
import { Color } from '@app/styles/themes/light/lightTheme';
import { DayjsDatePicker } from '@app/components/common/pickers/DayjsDatePicker';
import { Button } from '@app/components/common/buttons/Button/Button';
import { MembersTable } from '@app/components/tables/MembersTable/MembersTable';
import { SubTitle } from '@app/components/common/Title/Title.styles';
import { MysteryboxTable } from '@app/components/tables/MysteryboxTable/MysteryboxTable';
import { getYYYYMMDDBySeconds } from '@app/utils/utils';

const DiscordBotDataManage: React.FC = () => {
    const [timeRange, setTimeRange] = useState([0, 0]);
    const navigate = useNavigate();
    useEffect(() => {
        isValidToken(navigate);
    });

    const [dailyBotData, setDailyBotData] = useState([]);
    const [dailyMembersData, setDailyMembersData] = useState([]);
    const [dailyMysteryboxData, setDailyMysteryboxData] = useState([]);

    const pressSearchButton = async () => {
        const [startTime, endTime] = timeRange;
        const result = await getDailyBotData(startTime, endTime);

        if (!result) return;

        const awdData = result[0];
        const membersData = result[1];
        const mysteryboxData = result[2];

        if (awdData.length === 0 && membersData.length === 0 && mysteryboxData.length === 0) {
            setDailyBotData([]);
            setDailyMembersData([]);
            setDailyMysteryboxData([]);
        } else {
            const awdList = awdDataList();
            const mysteryboxList = mysteryboxDataList();

            for (const e of awdData) {
                for (const d of awdList) {
                    if (d.detailReason === e.reason) {
                        d.awd = +e.awd;
                        d.earn = +e.earn;
                        d.burn = +e.burn;
                        d.cnt = +e.cnt;
                    }
                    if (d.reasonGroup === e.reasonGroup) {
                        d.awd += +e.awd;
                        d.earn += +e.earn;
                        d.burn += +e.burn;
                        d.cnt += +e.cnt;
                    }
                }
            }

            for (const e of mysteryboxData) {
                for (const d of mysteryboxList) {
                    if (d.reason === e.reason) {
                        d.count = +e.count;
                        d.get = +e.get;
                        d.lost = +e.lost;
                    }
                    if (d.total) {
                        d.count += +e.count;
                        d.get += +e.get;
                        d.lost += +e.lost;
                    }
                }
            }

            setDailyBotData(awdList as React.SetStateAction<never[]>);
            setDailyMembersData(membersData as React.SetStateAction<never[]>);
            setDailyMysteryboxData(mysteryboxList as React.SetStateAction<never[]>);
        }
    };

    const desktopLayout = (
        <div>
            <Title> Discord Bot Data</Title>
            <SubTitle>( 최대 1개월까지 조회기간을 설정해보세요. )</SubTitle>

            <Card style={{ width: '60%', minWidth: 900 }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        marginBottom: 10
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            fontWeight: 'bold',
                            fontSize: 16,
                            width: 130,
                            marginRight: 8,
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: 48,
                            borderRadius: 7,
                            border: `1px solid ${Color.gray}`
                        }}
                    >
                        조회 기간
                    </div>
                    <DayjsDatePicker.RangePicker
                        style={{ width: '100%', marginRight: 8 }}
                        format="YYYY-MM-DD"
                        onChange={value => {
                            if (!value?.[0] || !value?.[1]) return;

                            const start = getYYYYMMDDBySeconds(new Date(value[0].unix() * 1_000));
                            const end = getYYYYMMDDBySeconds(new Date(value[1].unix() * 1_000));

                            setTimeRange([start, end]);
                        }}
                    />
                    <Button
                        onClick={() => {
                            pressSearchButton();
                        }}
                    >
                        조회하기
                    </Button>
                </div>
            </Card>

            <div style={{ height: 25 }} />
            <Title> 멤버 ﹒ 부스터 증감</Title>
            <Card style={{ width: '60%', minWidth: 900 }}>
                <MembersTable botData={dailyMembersData} />
            </Card>

            <div style={{ height: 25 }} />
            <Title> AWD 증감 </Title>
            <Card>
                <DailyBotDataTable botData={dailyBotData} />
            </Card>

            <div style={{ height: 25 }} />
            <Title> 미스테리박스 증감 </Title>
            <Card style={{ minWidth: 900 }}>
                <MysteryboxTable botData={dailyMysteryboxData} />
            </Card>
        </div>
    );

    return (
        <>
            <PageTitle>Discord Bot Data Manage</PageTitle>
            {desktopLayout}
        </>
    );
};

export default DiscordBotDataManage;
