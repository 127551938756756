import React from 'react';
import { DashboardOutlined } from '@ant-design/icons';
import { IoDiamond, IoGameController } from 'react-icons/io5';
import { FaCoins } from 'react-icons/fa';
import { BsFillBoxSeamFill, BsTable } from 'react-icons/bs';

export interface SidebarNavigationItem {
    title: string;
    key: string;
    url?: string;
    children?: SidebarNavigationItem[];
    icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
    {
        title: 'AWD Manage',
        key: 'awd-manage',
        url: '/awd-manage',
        icon: <IoDiamond />
    },
    {
        title: 'Event Point Manage',
        key: 'event-point-manage',
        url: '/event-point-manage',
        icon: <FaCoins />
    },
    {
        title: 'Mystery-Box Manage',
        key: 'mystery-box-manage',
        url: '/mystery-box-manage',
        icon: <BsFillBoxSeamFill />
    },
    {
        title: 'Sport-Bet Manage',
        key: 'sport-bet-manage',
        url: '/sport-bet-manage',
        icon: <IoGameController />
    },
    {
        title: 'Discord-Bot Data Manage',
        key: 'discord-bot-data-manage',
        url: '/discord-bot-data-manage',
        icon: <BsTable />
    }
];
