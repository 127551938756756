import { useEffect, useState, useCallback } from 'react';
import { TablePaginationConfig } from 'antd';
import { AwdTableRow, getBasicTableData, Pagination } from 'api/table.api';
import { Table } from 'components/common/Table/Table';
import { ColumnsType } from 'antd/es/table';
import { useMounted } from '@app/hooks/useMounted';
import { InitialState } from '@app/store/slices/logsSlice';
import { parceAdminReason } from '@app/api/\bpageApi/awdManage.api';
import { Color } from '@app/styles/themes/light/lightTheme';
import { FilterSearchBox } from '@app/components/common/FilterSearchBox/FilterSearchBox';
import { getYYYYMMDDHHII, uctToKst } from '@app/utils/utils';

const initialPagination: Pagination = {
    current: 1,
    pageSize: 20
};

export const AwdTable = (logs: InitialState) => {
    const [tableData, setTableData] = useState<{
        data: AwdTableRow[];
        pagination: Pagination;
        loading: boolean;
    }>({
        data: [],
        pagination: initialPagination,
        loading: false
    });
    const { isMounted } = useMounted();

    const fetch = useCallback(
        (pagination: Pagination) => {
            setTableData(tableData => ({ ...tableData, loading: true }));
            getBasicTableData(pagination).then(res => {
                if (isMounted.current) {
                    setTableData({
                        data: [],
                        pagination: res.pagination,
                        loading: false
                    });
                }
            });
        },
        [isMounted]
    );

    useEffect(() => {
        fetch(initialPagination);
    }, [fetch]);

    const handleTableChange = (pagination: TablePaginationConfig) => {
        fetch(pagination);
    };

    const columns: ColumnsType<AwdTableRow> = [
        {
            title: `Date`,
            dataIndex: `date`,
            render: (date: number) => {
                const kst = uctToKst(new Date(date * 1_000));
                return getYYYYMMDDHHII(kst);
            },
            sorter: (a: AwdTableRow, b: AwdTableRow) => (a.date as any).localeCompare(b.date)
        },
        {
            title: `userId`,
            dataIndex: `userId`,
            sorter: (a: AwdTableRow, b: AwdTableRow) => (a.userId as any) - (b.userId as any),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) =>
                FilterSearchBox({
                    setSelectedKeys,
                    selectedKeys,
                    confirm,
                    close,
                    clearFilters,
                    placeholder: `userId를 입력해주세요!`
                }),
            onFilter: (value: any, record: AwdTableRow) => {
                if (record.userId?.toString()) return record.userId?.includes(value.toString());
                return false;
            }
        },
        {
            title: `userName`,
            dataIndex: `username`,
            sorter: (a: AwdTableRow, b: AwdTableRow) => {
                if (!a.userName || !b.userName) return 0;
                return (a.userName as string).localeCompare(b.userName as string);
            }
        },
        {
            title: `awd`,
            dataIndex: `awd`,
            render: (awd: number) => {
                return (
                    <div
                        style={{
                            color: awd > 0 ? Color.darkMint : Color.pink
                        }}
                    >
                        {awd > 0 ? `+${awd}` : awd}
                    </div>
                );
            },
            sorter: (a: AwdTableRow, b: AwdTableRow) => (a.awd as any) - (b.awd as any)
        },
        {
            title: `Reason`,
            dataIndex: `reason`,
            render: (reason: number) => {
                return parceAdminReason(reason);
            },
            sorter: (a: AwdTableRow, b: AwdTableRow) => {
                return (a.reason as any) - (b.reason as any);
            }
        },
        {
            title: `admin`,
            dataIndex: `admin`,
            sorter: (a: AwdTableRow, b: AwdTableRow) =>
                (a.admin as string).localeCompare(b.admin as string)
        }
    ];

    return (
        <Table
            columns={columns}
            dataSource={logs.logs}
            loading={tableData.loading}
            onChange={handleTableChange}
            scroll={{ x: 800 }}
        />
    );
};
