import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/MainPages/LoginPage';
import SignUpPage from '@app/pages/MainPages/SignUpPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import AwdManagePage from '@app/pages/MainPages/AwdManagePage';
import EventPointManagePage from '@app/pages/MainPages/EventPointManagePage';
import MysteryBoxManagePage from '@app/pages/MainPages/MysteryBoxManagePage';
import SportBetManagePage from '@app/pages/MainPages/SportBetManagePage';
import DiscordBotDataManage from '@app/pages/MainPages/DiscordBotDataManagePage';

export const MAIN_PATH = '/';
export const MEDICAL_DASHBOARD_PATH = '/medical-dashboard';
export const AWD_MANAGE_PATH = '/awd-manage';
export const EVENT_POINT_MANAGE_PATH = '/event-point-manage';
export const MYSTERY_BOX_MANAGE_PATH = '/mystery-box-manage';
export const SPORT_BET_MANAGE_PATH = '/sport-bet-manage';
export const DISCORD_BOT_DATA_MANAGE_PATH = '/discord-bot-data-manage';

const AwdManage = withLoading(AwdManagePage);
const EventPointManage = withLoading(EventPointManagePage);
const MysteryBoxManage = withLoading(MysteryBoxManagePage);
const SportBetManage = withLoading(SportBetManagePage);
const AuthLayoutFallback = withLoading(AuthLayout);

export const AppRouter: React.FC = () => {
    const protectedLayout = (
        <RequireAuth>
            <MainLayout />
        </RequireAuth>
    );

    return (
        <BrowserRouter>
            <Routes>
                <Route path={MAIN_PATH} element={protectedLayout}>
                    <Route index element={<AwdManage />} />
                    <Route path={AWD_MANAGE_PATH} element={<AwdManage />} />
                    <Route path={EVENT_POINT_MANAGE_PATH} element={<EventPointManage />} />
                    <Route path={MYSTERY_BOX_MANAGE_PATH} element={<MysteryBoxManage />} />
                    <Route path={SPORT_BET_MANAGE_PATH} element={<SportBetManage />} />
                    <Route path={DISCORD_BOT_DATA_MANAGE_PATH} element={<DiscordBotDataManage />} />
                </Route>
                <Route path="/auth" element={<AuthLayoutFallback />}>
                    <Route path="login" element={<LoginPage />} />
                    <Route path="sign-up" element={<SignUpPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};
