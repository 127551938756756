import { notificationController } from '@app/controllers/notificationController';
import { confirmAlert, successAlert } from './Alert.api';
import { headers, CheckIfExpired } from './common.api';
import { API_BASE_URL } from '@app/consts/consts';

/* Get MysteryBox Reward and Cost Point */
export const getMysteryBoxPoint = async (): Promise<{
    cost: { buyMysteryBox: string; openMysteryBox: string };
    reward: string[];
} | void> => {
    try {
        const res = await fetch(`${API_BASE_URL}/admin/cost/mysterybox`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });
        await CheckIfExpired(res.status);
        const data = await res.json();
        return data;
    } catch (err: any) {
        notificationController.error({
            message: `mysteryBoxPoint 조회에 실패했습니다. : ${err.message}`
        });
    }
};

/* Remove List To Give Reward */
export const pressRewardButton = async (
    rewardName: string,
    rewardAmount: string,
    userId: string
): Promise<void> => {
    try {
        const message = `
  ❗️ <strong> ${userId} </strong>님에게 <br>
  <strong> ${rewardName} ${rewardAmount}개</strong>를 지급했나요? <br>
  확인을 누르면 지급 목록에서 사라집니다. <br><br>
  <strong>다시 한번 확인해주세요!</strong>
  `;

        if (!(await confirmAlert(message))) return;
        const res = await fetch(`${API_BASE_URL}/admin/cost/mysterybox`, {
            method: 'PATCH',
            headers: headers(),
            body: JSON.stringify({ userId, rewardName, rewardAmount })
        });

        await CheckIfExpired(res.status);
        const data = await res.json();
        if (data.message === `success`) {
            await successAlert('지급에 성공하였습니다!');
        }
    } catch (err: any) {
        notificationController.error({
            message: `mysteryBoxPoint 지급에 실패했습니다. : ${err.message}`
        });
    }
};
